.animated-images {
    position: relative;
    height: 100vh;
    overflow: hidden;
  
    &__image {
      position: absolute;
      width: 200px;
      height: 200px;
      background-color: #ccc;
      transition: transform 0.5s, opacity 0.5s;
  
      &--left {
        left: 10%;
        top: 20%;
      }
  
      &--right {
        right: 10%;
        top: 20%;
      }
  
      &.animated {
        transform: rotate(360deg);
        opacity: 0;
      }
    }
  }
  