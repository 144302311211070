.order {

    padding: 15px 10px 0px 15px;
    background-color: #f2f2f2;

    .icon {
        opacity: .5;
        cursor: pointer;
        color: #555;
        /* Adjust icon color */
        transition: color 0.3s ease;
    }

    .order-header {
        margin-top: 25px;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 650px;

        h2 {
            font-size: 1em;
            color: gray;
        }

        h1 {
            font-size: 25px;
        }

        h6 {
            color: gray;

        }

        button {
            cursor: pointer;
        }

        .right {
            display: flex;
            gap: 10px;
        }

        .left {
            display: flex;
        }

        .order-header-arrow {
            display: flex;
            align-items: center;
        }

        .back-button {
            margin-right: 10px;
            display: flex;
            align-items: center;
            background: none;
            border: none;
            cursor: pointer;
            font-size: 1rem;
            color: #000;

            svg {
                margin-right: 5px;
            }

            &:hover {
                color: #4c4c4f;
            }
        }
    }

    .order-content {
        padding: 10px 20px;
        // background: #fff;
        border-radius: 10px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        // margin-top: 10px;
        gap: 5px;
        display: flex;
        flex-direction: column;
        display: flex;
        max-width: 650px;

        .title {
            font-size: 20px;
            color: #80808096;
            margin-bottom: 10px;

        }

        .order-details-grid {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 6px 0px 10px 0px;

            .order-info,
            .order-summary,
            .order-shipping-details,
            .order-packageInfo {
                display: flex;
                flex-direction: column;
                background-color: white;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

                .warning {
                    color: red;
                    font-size: 16px;
                    margin-top: 5px;
                }

                .title {
                    font-size: 20px;
                    color: #80808096;
                    margin-bottom: 10px;

                }

                .detail-item {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    // margin-top: 10px;
                    align-items: center;

                    .item-key {
                        font-weight: 400;
                    }
                }

                select {
                    width: 40%;
                    padding: 10px;
                    margin-bottom: 10px;
                    border-radius: 5px;
                    border: 1px solid #ccc;
                    background-color: #fff;
                    font-size: 16px;
                    color: #333;
                }

                .status {
                    display: flex;
                    align-items: center;

                    .item-value {
                        // margin-right: 10px;
                        // display: flex;
                    }

                    svg {
                        cursor: pointer;
                    }
                }
            }


        }

        .order-products {
            // margin-top: 20px;

            background-color: white;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            gap: 10px;

            .table {
                .table-cell {
                    padding: 10px;
                }

                .cell-wrapper {
                    display: flex;
                    align-items: center;

                    .image {
                        width: 50px;
                        height: 50px;
                        margin-right: 10px;
                        object-fit: contain;
                    }
                }
            }

            .title {
                font-size: 20px;
                color: gray;
                margin-bottom: 10px;

            }
        }

        .order-customer {

            background-color: white;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            .details {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .detailItem {
                    display: flex;
                    justify-content: space-between;

                    // margin-bottom: 10px;
                    // margin-top: 10px;

                    .itemKey {
                        color: #4c4c4f;

                    }

                    .itemValue {
                        color: #4c4c4f;
                        text-align: right;
                    }
                }
            }
        }

        .order-status {
            margin-bottom: 20px;
        }

        .order-actions {
            margin: 20px 0px 20px 0px;
            display: flex;
            gap: 10px;

        }

    }

    .order-packageInfo {
        // margin-top: 20px;

        .package-details {
            display: flex;
            flex-direction: column;


            .detail-item {
                display: flex;
                justify-content: space-between;

                .item-key {
                    font-weight: 600;
                }

                .item-value {
                    font-weight: 400;
                }
            }


        }
    }

    span {
        color: #4c4c4f;
        text-align: right;
    }


    .package-overlay {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        padding: 30px;

        .content {
            background: #fff;
            padding: 40px;
            border-radius: 10px;
            // text-align: center;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            position: relative;

            .close-button {
                position: absolute;
                top: 10px;
                right: 10px;
                background: none;
                border: none;
                cursor: pointer;
            }

            h2 {
                font-size: 1.5rem;
                margin-bottom: 10px;
                font-weight: bold;
            }

            p {
                font-size: 1rem;
                color: #666;
                margin-bottom: 20px;
            }

            .input-row {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                .input-group {
                    flex: 1;
                    margin-right: 10px;

                    &.full-width {
                        flex: 2;
                        display: flex;
                        align-items: center;

                        input {
                            flex: 1;
                        }
                    }

                    label {
                        display: block;
                        margin-bottom: 5px;
                        font-weight: bold;
                    }

                    input {
                        width: 100%;
                        padding: 10px;
                        border: 1px solid #ddd;
                        border-radius: 5px;
                        background: #f5f5f5;
                    }
                }

                .unit {
                    padding: 10px;
                    font-weight: bold;
                    color: #666;
                }
            }

            .button-row {
                display: flex;
                justify-content: flex-end;
                gap: 10px;

                .cancel-button,
                .save-button {
                    padding: 10px 20px;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }

                .cancel-button {
                    background: #ddd;
                    color: #333;
                }

                .save-button {
                    background: #007bff;
                    color: white;
                }
            }
        }
    }


}

.loaderbox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.stepper-container {
    overflow-x: auto;
    padding-bottom: 15px;
}




@media (max-width: 1000px) {
    .order-details-grid {
        flex-direction: row;
    }

    .order {

        .button-delete,
        .button-cancel,
        .button-duplicate {
            font-size: 10px;
        }
    }
}

@media (max-width: 650px) {

    .order .order-header {

        h1 {
            font-size: 20px;
        }

        h6 {
            font-size: 8px;
        }

    }

    .iconContainer {
        display: flex;
        gap: 25px !important;

    }


}

@media (max-width: 500px) {

    .order {
        padding: 15px 10px 0px 10px;

        .order-header {

            padding: 0px 10px;

            h1 {
                font-size: 15px;
            }

            h6 {
                font-size: 8px;
            }

            .right {
                gap: 5px;
            }


        }
    }

    .order .order-content {
        font-size: small;
        padding: 10px 10px;
    }

    .iconContainer {
        display: flex;
        gap: 20px !important;

    }

}

.order-error {
    .error-label {
        color: red;
        font-weight: 400;

    }
}

.price-overlay,
.customer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    padding: 30px;

    .content {
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        position: relative;
        width: 450px;
        max-width: 100%;
        max-height: 90vh; // Ensure the content doesn't take more than 80% of viewport height
        overflow-y: auto; // Add scrollbar if content overflows

        .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            background: none;
            border: none;
            cursor: pointer;
        }

        h2 {
            font-size: 1.5rem;
            margin-bottom: 10px;
            font-weight: bold;
        }

        p {
            font-size: 1rem;
            color: #666;
            margin-bottom: 20px;
        }

        .input-row {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .input-group {
                flex: 1;
                margin-right: 10px;

                &.full-width {
                    flex: 2;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    input,
                    textarea {
                        width: 200px; // Set a fixed width for the input fields
                    }
                }

                label {
                    display: block;
                    margin-bottom: 5px;
                    font-weight: bold;
                }

                input,
                textarea {
                    width: 200px; // Set a fixed width for the input fields
                    padding: 10px;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    background: #f5f5f5;
                }
            }

            .unit {
                padding: 10px;
                font-weight: bold;
                color: #666;
            }
        }

        .button-row {
            display: flex;
            justify-content: flex-end;
            gap: 10px;

            .cancel-button,
            .button-primary {
                padding: 10px 20px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
            }

            .cancel-button {
                background: #ddd;
                color: #333;

                &:hover {
                    background: #bebcbc;
                }
            }

            .button-primary {
                background: #030303;
                color: white;

                &:hover {
                    background: white;
                    border: 1px solid black;
                    color: black;
                }
            }
        }
    }
}

.product-listing {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;

    .product-item {
        display: flex;
        align-items: center;
        // flex-direction: column;
        padding: 5px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        gap: 8px;
        background-color: #f4f4f4;

        .product-thumbnail {
            flex-shrink: 0;

            img {
                width: 30px; // Smaller thumbnail size
                height: 30px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .product-details {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 3px;

            h4 {
                margin: 0;
                font-size: 12px;
                font-weight: 500;
                color: #333;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%; // Ensures the text doesn't overflow
            }

            .inputs {
                display: flex;
                gap: 5px;

                .input-group {
                    display: flex;
                    flex-direction: column;
                    gap: 2px;

                    label {
                        font-size: 10px;
                        font-weight: 500;
                        color: #666;
                    }

                    input {
                        padding: 4px;
                        border: 1px solid #ccc;
                        border-radius: 3px;
                        font-size: 10px;
                        width: 50px;
                    }

                    input[disabled] {
                        background-color: #e9ecef;
                    }
                }
            }
        }
    }

    .add-product-button {
        background-color: #5f5f5f;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 6px 10px;
        font-size: 12px;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.2s;
        margin-top: 10px;
        width: 100px;
        border: 1px solid #000000;

        &:hover {
            background-color: #ffffff;
            color: #000000;
            border: 1px solid #000000;
            // transform: scale(1.01);
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 2px #777575
        }

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    }
}


.toggle-collapse {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;

    span {
        font-size: 14px;
        font-weight: 600;
        color: #333;
    }

    .toggle-button {
        background: none;
        border: none;
        font-size: 16px;
        cursor: pointer;
        color: #333;
    }
}






.editIcon {

    cursor: pointer;
    align-self: flex-end;
    color: #212122;
    opacity: .2;
    font-size: 20px;

    &:hover {
        color: #353535;
        opacity: 1;
    }
}

.stepper-container {
    overflow-x: auto;
}

/* WebKit browsers */
.stepper-container::-webkit-scrollbar {
    width: 8px;
}

.stepper-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.stepper-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.stepper-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Firefox */
.stepper-container {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

// Fade-in animation edit 
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

// Minimize animation
@keyframes minimize {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0.5);
    }
}

.order-customer .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.order-shipping-details .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.order-customer .iconContainer {
    display: flex;
    gap: 30px;

}

.order-customer .icon {
    opacity: .5;
    cursor: pointer;
    color: #555;
    /* Adjust icon color */
    transition: color 0.3s ease;
}

.order-customer .icon:hover {
    color: #000;
    /* Change color on hover */
    opacity: 1;

}

// ... (existing styles)

.iconContainer {
    display: flex;
    align-items: center;

    .icon {
        cursor: pointer;
        margin-left: 10px;
        color: #000000;
        transition: color 0.3s ease;

        &:hover {
            color: #706e6e;
        }
    }
}



.mark-as-paid-button {
    background-color: rgb(45, 173, 45);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 12px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;

    &:hover {
        background-color: rgb(5, 147, 5);
        // transform: translateY(-2px);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }

    &:active {
        transform: translateY(0);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(37, 117, 252, 0.5);
    }
}


// Order.scss

.order-timeline {
    margin-top: 20px;
    // padding: 20px;
    // background-color: #f9f9f9;
    border-radius: 8px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .timeline-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 10px;
        color: #333;
    }

    .timeline-list {
        list-style: none;
        padding: 0;

        .timeline-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            padding: 10px;
            // background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

            &:last-child {
                margin-bottom: 0;
            }

            .timeline-content {
                display: flex;
                flex-direction: column;

                .timeline-date {
                    font-size: 0.9rem;
                    color: #888;
                    margin-bottom: 5px;
                    text-align: justify;
                }

                .timeline-remarks {
                    font-size: 1rem;
                    color: #555;
                }
            }
        }
    }
}


.add-product-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .overlay-content {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        width: 90%; // Responsive width
        max-width: 500px; // Maximum width for larger screens
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        position: relative;
        overflow: hidden; // Prevent overflow of content

        .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            background: none;
            border: none;
            cursor: pointer;
            font-size: 16px;
            color: #333; // Change color for better visibility
        }

        h2 {
            margin-bottom: 15px;
            font-size: 1.5rem; // Increased font size for better readability
            color: #333; // Darker color for better contrast
        }

        input[type="text"] {
            width: 100%;
            padding: 10px;
            margin-bottom: 15px;
            border: 1px solid #ccc;
            border-radius: 4px;
            transition: border-color 0.3s;

            &:focus {
                border-color: #4CAF50; // Highlight border on focus
                outline: none; // Remove default outline
            }
        }

        .product-list {
            background-color: rgba(255, 255, 255, 0.9);
            border-radius: 10px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            z-index: 10;
            max-height: 300px; // Increased height for better visibility
            overflow-y: auto; // Enable scrolling if needed
            padding: 10px;
            margin-top: 10px; // Adjusted margin for spacing

            /* Styled scrollbar */
            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #ccc;
                border-radius: 10px;
            }

            .product-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                border-bottom: 1px solid #f0f0f0;
                transition: background-color 0.2s;

                &:hover {
                    background-color: #f9f9f9;
                }

                span {
                    font-size: 0.9rem;
                    text-align: justify;
                    color: #555; // Darker text for better readability
                }

                button {
                    padding: 6px 12px;
                    background-color: #4CAF50;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: background-color 0.3s;

                    &:hover {
                        background-color: #45a049;
                    }
                }
            }
        }
    }
}

// ... (rest of the existing styles)