.checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 13px 20px 10px;
    background-color: #f9f9f9;

    &__title {
        text-align: left;
        justify-content: left;
        display: flex;
        align-items: flex-end;
        width: 100%;
        font-size: 20px;
        margin-bottom: 12px;
        font-weight: 400;
    }

    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    &__form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        &--inline {
            flex-direction: row;
            justify-content: space-between;

            input {

                width: 100%;
            }

            &>.checkout__form-group-item {
                flex: 1;

                &:not(:last-child) {

                    margin-right: 10px;

                }
            }
        }
    }

    &__label {
        margin-bottom: 2px;
        font-weight: 300;
    }

    &__input {
        padding: 3.5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
    }

    &__submit-button {
        padding: 10px 15px;
        font-size: 1rem;
        font-weight: 400;
        color: #fff;
        background-color: #000000;
        border: 1px solid black;
        border-radius: 4px;
        cursor: pointer;
        align-self: flex-end;

        &:hover {
            background-color: #ffffff;
            color: black;
            border: 1px solid black;
        }
    }
}

.checkout__products {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    border: 1px solid #ccc;
}

.checkout__product {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 10px;
    border-bottom: 1px solid #eee;
    border-radius: 8px;
    align-items: center;

    &:last-child {
        border-bottom: none;
    }
}

.checkout__product-image img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 5px;
}

.checkout__product-details {
    display: flex;
    flex-direction: column;
}

.checkout__product-name {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
}

.checkout__product-info {
    display: flex;
    gap: 20px;
    font-size: 16px;
    color: #555;
}

.checkout__product-price {
    font-weight: bold;
}

.checkout__product-qty {
    display: flex;
    align-items: center;

    button {
        background: #f0f0f0;
        border: none;
        padding: 5px;
        cursor: pointer;

        &:hover {
            background: #e0e0e0;
        }
    }

    span {
        margin: 0 10px;
        font-size: 16px;
    }
}


.checkout__price {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    &-item {
        display: flex;
        justify-content: space-between;
        padding: 6px 0;
        border-bottom: 1px solid #eee;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:last-child {
            border-bottom: none;

            span {
                font-weight: bold;

            }
        }

        span {
            font-size: 14px;
            font-weight: 400;
            color: #333;
        }

        .checkout__price-item-label {
            font-weight: 600;
            color: #000;
        }

        .checkout__price-item-value {
            font-weight: 500;
            color: #555;
        }
    }

    &__total {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-top: 1px solid #eee;
        font-size: 18px;
        font-weight: bold;

        span {
            font-weight: 700;
        }
    }
}

.checkout__payment-method {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    border: 1px solid #ccc;

    &-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    &-option {
        display: flex;
        align-items: center;
        padding: 10px;
        margin-bottom: 8px;
        background-color: #f7f7f7;
        border-radius: 6px;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: #ebebeb;
        }

        input[type="radio"] {
            margin-right: 12px;
            accent-color: black;
            cursor: pointer;
        }

        &-label {
            font-size: 16px;
            font-weight: 400;
        }

        &--selected {
            background-color: #e0e0e0;
        }
    }

    &-description {
        font-size: 14px;
        font-weight: 300;
        color: #555;
        margin-top: 5px;
        padding-left: 32px; // aligns with radio button spacing
    }
}

.checkout__payment-method-option-label {
    display: flex;
    align-items: center;
    font-size: 14px;

    .razorpay-logo {
        width: 100px;
        height: auto;
        margin-left: 10px; // Space between text and logo
    }
}

@media (min-width: 800px) {

    .checkout {

        .container {
            display: flex;
            flex-direction: row;
            gap: 20px;

            .left {
                width: 45%;

                .checkout__products {

                    img {
                        width: 80px;
                        height: 80px;
                    }

                    .checkout__product-name {
                        font-size: 16px;

                    }

                    .checkout__price-item {

                        span {
                            font-size: 16px;
                        }
                    }
                }
            }

            .right {

                width: 55%;
            }
        }
    }

}

.order-dismissed-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    padding: 1rem;

    .overlay-content {
        background-color: white;
        border-radius: 1rem;
        padding: 2rem;
        max-width: 28rem;
        width: 100%;
        box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
        position: relative;
    }

    .close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: #9ca3af;
        font-size: 1.5rem;
        font-weight: bold;
        transition: color 0.3s;

        &:hover {
            color: #4b5563;
        }
    }

    .content-wrapper {
        text-align: center;
    }

    .error-icon {
        width: 5rem;
        height: 5rem;
        margin: 0 auto 1.5rem;
        background-color: #fee2e2;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-size: 2.25rem;
            font-weight: bold;
            color: #dc2626;
        }
    }

    h2 {
        font-size: 1.875rem;
        font-weight: bold;
        margin-bottom: 1rem;
        color: #1f2937;
    }

    .error-message {
        color: #4b5563;
        margin-bottom: 2rem;
    }

    .order-details {
        background-color: #f9fafb;
        border-radius: 0.75rem;
        padding: 1rem;
        margin-bottom: 2rem;

        h3 {
            font-weight: 600;
            font-size: 1.125rem;
            margin-bottom: 0.5rem;
            color: #374151;
        }

        p {
            color: #4b5563;
        }
    }

    // .retry-button {
    //   width: 100%;
    //   background-color: #2563eb;
    //   color: white;
    //   padding: 0.75rem 1.5rem;
    //   border-radius: 9999px;
    //   font-weight: 600;
    //   transition: all 0.3s ease-in-out;

    //   &:hover {
    //     background-color: #1d4ed8;
    //     transform: scale(1.05);
    //   }

    //   &:focus {
    //     outline: none;
    //     box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.5);
    //   }
    // }
}

.checkout__label {
    &--optional {
        font-size: 0.8em;
        color: #666;
        margin-left: 5px;
        font-style: italic;
    }
}

.checkout__more-products {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #ddd;

    span {
        font-size: 14px;
        color: #555;
    }

    button {

        border: none;
        padding: 8px 16px;
        background-color: #f0f0f0;
        color: #333;
        border: 1px solid #ccc;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.2s ease;

        &:hover {

            transform: translateY(-2px);
        }
    }
}