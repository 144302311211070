.policy-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background-color: #f5f5f5; // Light background for contrast

    .loading, .error, .not-found {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        font-size: 1.2rem;
        color: #333;
        text-align: center;
        padding: 20px;
    }

    .policy-content {
        flex-grow: 1;
        max-width: 800px;
        // width: 100%;
        margin: 2rem auto;
        padding: 2rem;
        background-color: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        h1 {
            font-size: 2rem;
            margin-bottom: 1.5rem;
            color: #333;
            text-align: center;
            border-bottom: 2px solid #eaeaea;
            padding-bottom: 1rem;
        }

        div {
            font-size: 1rem;
            line-height: 1.6;
            color: #444;

            p {
                margin-bottom: 1rem;
                text-align: justify;
            }

            h2, h3, h4, h5, h6 {
                margin-top: 1.5rem;
                margin-bottom: 1rem;
                color: #333;
            }

            ul, ol {
                margin-bottom: 1rem;
                padding-left: 2rem;
            }

            a {
                color: #0066cc;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .policy-content {
            padding: 1rem;
            margin: 1rem;

            h1 {
                font-size: 1.5rem;
            }
        }
    }
}