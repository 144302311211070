.layout {
    // display: flex;
    // min-width: 100vw;
    // background-color: antiquewhite;
    .layout__container {
        margin-left: 170px;
        // transition: margin-left .2s ease-in;
        
        &-content{
            // min-height: 100vh;
            padding: 15px 10px 0px 15px;
            background-color: #f2f2f2;
            // height: 100vh;
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            gap: 15px;
            // align-items: center;
        }

        @media (max-width:525px) {
            display: none;
            margin-left: 170px;
        }
    }

    .layout__container.inactive {
        margin-left: 50px;
        
        @media (max-width:525px) {
            // margin-left: 80px;
            margin-left: 0px;
            display: flex;
            flex-direction: column;
        }
    }
}

.select-plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}