.contact-us {
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    background-color: black;

    &__hero {
        padding: 40px 20px;
        text-align: center;
    }

    &__title {
        font-size: 24px;
        color: #ffffff;
    }

    &__subtitle {
        font-size: 14px;
        color: #777;
    }

    &__details {
        text-align: center;
        margin: 20px 0;
        color: #ffffff;
        font-size: 14px;

        a {
            color: #57ff12;
            text-decoration: none;
        }
    }

    &__form {
        max-width: 600px;
        margin: 40px auto;
        padding: 20px;
        background-color: #1c1c1c;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    &__input-group {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;

        &-label {
            color: #ffffff;
            margin-bottom: 8px;
            font-size: 14px;
        }

        &-input {
            padding: 10px;
            font-size: 14px;
            color: #ffffff;
            background-color: #333;
            border: 1px solid #444;
            border-radius: 4px;
        }

        &-textarea {
            min-height: 100px;
            padding: 10px;
            font-size: 14px;
            color: #ffffff;
            background-color: #333;
            border: 1px solid #444;
            border-radius: 4px;
        }
    }

    &__button {
        padding: 12px 20px;
        background-color: #ffffff;
        color: #000000;
        border-radius: 4px;
        font-weight: 600;
        cursor: pointer;
        text-decoration: none;
        display: block;
        text-align: center;
    }

    &__success {
        color: #57ff12;
        text-align: center;
    }
    
    &__error {
        text-align: center;
        color: rgb(255, 0, 0);
    }

    &__cta {
        text-align: center;
        margin-top: 40px;
        padding: 40px 20px;
        background-color: #000000;
        color: #fff;

        &-text {
            font-size: 1.25rem;
        }

        &-button {
            display: inline-block;
            padding: 10px 20px;
            background-color: #fff;
            color: #000000;
            border-radius: 4px;
            text-decoration: none;
            font-weight: 600;
            margin-top: 20px;
        }
    }
}
