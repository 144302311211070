.footer {
  background-color: #f8f8f8;
  // padding: 20px 0;

  // border-top: 1px solid #e7e7e7;
  .footer-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 20px;
    gap: 10px;
  }

  .footer-section {
    flex: 1 1;
    min-width: 200px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h4 {
      font-size: 18px;
      margin-bottom: 10px;
      color: #333;
    }

    p {
      font-size: 14px;
      margin: 5px 0;
      // color: #666;
    }

    .social-links {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: center;

      a {
        text-decoration: none;
        // color: #007bff;
        transition: color 0.3s;

        &:hover {
          color: #0056b3;
        }
      }
    }
  }

  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #e7e7e7;

    p {
      font-size: 14px;
      // color: #666;
    }
  }
  .policy-links {
    display: flex;
    flex-direction: column;
    gap: 10px;

    a {
      text-decoration: none;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .prodinent-section {
    text-align: center;
    margin-top: 1rem;
    font-size: 0.9em;
    
  }
}
