.sidebar {
    position: fixed;
    width: 170px;
    height: 100vh;

    // background-image: linear-gradient(to left, #fdfefe, #e2f4f0, #cde9da, #c1dcbf, #bfcda0);
    // background: rgb(255, 93, 93);
    // background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(150, 73, 24, 1) 44%, rgba(52, 51, 51, 1) 100%);
    // background-color: black;
    background-color: #1E1E1E; 

    display: flex;
    flex-direction: column;
    transition: width .20s ease-in;
    // padding: 10px;
    z-index: 999;

    &__toggle {
        margin-left: 30px;
        margin-top: 19px;

    }

    .reorder-icon {
        transform: scale(1);
        color: rgb(255, 255, 255);
    }


    &__image {
        width: 100%;
        height: 35%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        margin-top: 30px;


        &-box {
            width: 95%;
            height: 95%;
            // background-color: rgb(255, 255, 255);
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            // transition: width 2s ease-in;
            img {

                color: white;
                align-items: center;
                transform: scale(2);

            }


        }

        &-icon {
            color: white;
            width: 100px;
            height: 100px;

        }

    }

    &__divider {
        width: 100%;
        height: 1px;
        border-radius: 1px;
        background: rgba(51, 51, 51, 0.192);
        padding: 0px 5px;

    }
    &__menu {
        margin: 5px 0;
        display: flex;
        flex-direction: column;
        width: 170px;
        height: 100%;
        margin-top: 35px;
        overflow-y: auto; // Enables vertical scrolling when content exceeds the container height
    
        &-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            width: 100%;
            cursor: pointer;
    
            &-icon {
                width: 30%;
                display: flex;
                justify-content: center;
                text-align: left;
            }
    
            &-name {
                width: 70%;
                padding: 12px;
                font-size: 15px;
                text-align: left;
                color: rgb(255, 255, 255);
            }
        }
    
        // Optional custom scrollbar styling (for webkit browsers)
        &::-webkit-scrollbar {
            width: 8px;
            color: gray;
            border: none;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 0.3); // Customize scrollbar color
            border-radius: 4px;
        }
    
        &::-webkit-scrollbar-track {
            background-color: transparent; // Customize scrollbar track color
        }
    }
    

    .mui-style {
        transform: scale(0.9);
        // color: #dadada;
        color: rgb(255, 255, 255);
    }

    .sidebar__menu-item:hover {
        background-color: rgba(129, 125, 125, 0.192);
        border-radius: 50px;
    }


}

.sidebar.inactive {
    width: 50px;
    display: flex;
    align-items: center;

    .sidebar {
        &__image {
            display: none;
        }

        &__menu__accountList {
            visibility: hidden;

        }

        &__menu-item {
            // margin-left: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 8%;

            // background-color: #fff;

            &-name {
                display: none;
            }
        }

        &__menu {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;
            height: 100%;
            width: 80px;
            // gap: 20px;
            margin-top: 40px;
        }

        &__divider {
            display: none;
        }

    }

    .mui-style {
        transform: scale(0.8);
    }


}
.sidebar__menu-item-submenu {
    display: flex;
    flex-direction: column;
    margin-left: 50px; // Adjust as needed
    padding-left: 10px;
    // border-left: 2px solid rgba(255, 255, 255, 0.2);

    &-item {
        padding: 8px 0;
        color: rgb(255, 255, 255);
        cursor: pointer;
        font-size: 12px;
        &:hover {
            background-color: rgba(129, 125, 125, 0.192);
            border-radius: 50px;
        }
    }
}


@media (max-width:525px) {
    .sidebar {
        // position: ;
        width: 100vh;
        height: 100%;
        // display:none;


        &__image {
            display: none;

        }

        &__divider {
            display: none;

        }

        &__menu {
            // display: none;
            // background-color: rgb(226, 0, 0);

        }

        .mui-style {
            // display: none;
        }

        &__toggle {
            position: absolute;
            opacity: 0.4;
            

        }
    }

    .sidebar.inactive {
        max-width: 0px;
        height: 0px;
        // margin: 22px 0px;


        .mui-style {
            display: none;
        }

    }


}

.home__container.inactive {
    margin-left: 80px;


    @media (max-width:525px) {
        margin-left: 80px;
        display: contents;



    }


}