/* WalletModal.scss */
.wallet-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; // Ensure it appears above other elements
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 400px; // Adjust width as needed
    text-align: center;
    position: relative; // Added for positioning the passbook label

    h2 {
        margin-bottom: 10px;
        color: #333; // Set heading color to dark for visibility
    }

    .passbook-label {
        position: absolute; // Positioning the label
        top: 10px; // Adjust as needed
        right: 10px; // Adjust as needed
        text-align: right; // Align text to the right
    }

    .view-passbook-button {
        background: none;
        border: none;
        color: #007bff; // Link color
        cursor: pointer;
        font-size: 11px; // Adjust font size as needed

        &:hover {
            text-decoration: underline; // Underline on hover
        }
    }

    .current-balance {
        font-size: 16px; // Increase font size for better visibility
        margin-bottom: 15px; // Add space below
        color: #555; // Set a contrasting color for visibility
    }

    .balance {
        font-weight: bold;
        color: green; // Highlight current balance
    }

    input {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .amount-options {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        button {
            flex: 1;
            margin: 0 5px;
            padding: 10px;
            border: none;
            border-radius: 4px;
            background-color: #f0f0f0;
            cursor: pointer;

            &:hover {
                background-color: #e0e0e0; // Change color on hover
            }
        }
    }

    .summary {
        text-align: left;
        margin-bottom: 20px;

        .payable-amount {
            font-weight: bold;
            color: #007bff; // Highlight payable amount
        }
    }

    .continue-button {
        padding: 10px 15px;
        border: none;
        border-radius: 4px;
        background-color: #28a745;
        color: white;
        cursor: pointer;
        margin-bottom: 10px;

        &:hover {
            background-color: #218838; // Change color on hover
        }
    }

    .success-message {
        text-align: center;
        /* Center the text */
        margin: 20px 0;
        /* Margin for spacing */
    }

    .success-icon {
        color: green;
        /* Green color for the tick */
        font-size: 40px;
        /* Size of the tick icon */
    }

    h2 {
        color: #333; // Dark color for the heading
        margin-bottom: 10px; // Space below the heading
    }

    p {
        color: #555; // Slightly lighter color for the text
        margin: 5px 0; // Space above and below the paragraph
    }

    .failure-message {
        text-align: center; // Center the text
        margin: 20px 0; // Margin for spacing
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        gap: 10px;
    }

    .failure-icon {
        color: red; // Red color for the failure icon
        font-size: 40px; // Size of the icon
        margin-bottom: 10px; // Space below the icon
    }
}

.close-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #dc3545;
    color: white;
    cursor: pointer;
    position: absolute; // Positioning the close button
    top: 20px; // Adjust as needed
    right: 20px; // Adjust as needed

    &:hover {
        background-color: #c82333; // Change color on hover
    }
}