.forgot-password {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      background-color: #f9f9f9;
      padding: 20px;
    }
  
    &__content {
      background-color: #ffffff;
      padding: 30px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      width: 100%;
      max-width: 400px;
      text-align: center;
    }
  
    &__title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  
    &__form {
      display: flex;
      flex-direction: column;
    }
  
    &__form-group {
      margin-bottom: 15px;
      position: relative;
    }
  
    &__label {
      margin-bottom: 5px;
      display: block;
      font-weight: 500;
    }
  
    &__input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 1rem;
    }
  
    &__input:focus {
      border: 2px solid #f2796e;
    }
  
    &__button {
      margin-top: 15px;
      width: 100%;
      height: 45px;
      background-color: #000000;
      color: white;
      border: none;
      padding: 12px 64px;
      font-size: 20px;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease-out;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  
    &__button:hover {
      color: #000000;
      background-color: #ffffff;
      transition: all 0.2s ease-out;
      border: 1px solid black;
    }
  
    &__loader {
      margin-top: 15px;
    }
  
    &__success-message, &__error-message {
      margin-top: 20px;
      font-size: 14px;
      color: #d9534f;
    }
  
    &__success-message {
      color: #5cb85c;
    }
  
    &__back {
      margin-top: 20px;
    }
  
    &__back a {
      color: #f00946;
      cursor: pointer;
      text-decoration: none;
    }
  
    &__back a:hover {
      color: rgb(39, 106, 251);
    }
  }
  
  @media (max-width: 425px) {
    .forgot-password__container {
      padding: 10px;
    }
  
    .forgot-password__content {
      padding: 20px;
    }
  
    .forgot-password__title {
      font-size: 20px;
    }
  
    .forgot-password__button {
      font-size: 18px;
    }
  
    .forgot-password__success-message, .forgot-password__error-message {
      font-size: 12px;
    }
  }
  