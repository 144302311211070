@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.track-order {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 92vh;
  background-color: #f0f2f5;
  font-family: 'Poppins', sans-serif;

  .order-card {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 90%;
    max-width: 600px;
    text-align: center;
  }

  h1 {
    color: #333;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .order-id {
    font-size: 1rem;
    color: #666;
    margin-bottom: 30px;
  }

  .stepper-container {
    margin: 40px 0;

    .MuiStepLabel-label {
      font-family: 'Poppins', sans-serif;
    }
  }

  .order-details {

    display: flex;
    flex-direction: column;
    align-items: center;

    .status-badge {
      display: inline-block;
      // background-color: #4caf50;
      color: white;
      padding: 10px 20px;
      border-radius: 50px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .shipping-service,
    .waybill-number {
      font-size: 1rem;
      color: #666;
      margin: 2px;

      span {
        font-weight: 500;
        color: #333;
        font-style: italic;

      }
    }
  }
}

.error {
  text-align: center;
  font-size: 1.2rem;
  color: #f44336;
  margin-top: 50px;
}

// Responsive adjustments
@media (max-width: 600px) {
  .track-order {
    .order-card {
      padding: 30px;
    }

    h1 {
      font-size: 2rem;
    }

    .stepper-container {
      margin: 30px 0;
    }
  }
}

.powered-by {
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  color: #666;

  p {
    margin: 5px 0;
  }

  a {
    color: #007BFF;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.shipping-service-image {
  max-width: 100px;
  height: auto;
  margin: 10px 0;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
}