.shipping-delivery {
    padding: 20px;
    background-color: #000; // Black background color
    text-align: center;

    &__hero {
        padding: 40px 20px;
        // background-color: #333;

        &-title {
            font-size: 24px;
            margin-bottom: 20px;
            color: #fff; // White color for title
        }

        &-subtitle {
            font-size: 14px;
            color: #777;
            max-width: 800px;
            margin: 0 auto;
        }
    }

    &__content {
        max-width: 800px;
        margin: 0 auto;
        color: #fff; // White text color
    }

    &__section {
        margin-bottom: 30px;
        text-align: left;
        text-align: center;

        &-title {
            font-size: 14px;
            margin-top: 24px;
            margin-bottom: 10px;
            color: #fff;
        }

        &-description {
            font-size: 12px;
            line-height: 1.5;
            color: #bbb; // Softer white for description text
            text-align: justify;
        }
    }


    &__cta {
        margin-top: 40px;
        padding: 20px;
        background-color: #1c1c1c;

        &-text {
            font-size: 14px;
            color: #fff;
            max-width: 600px;
            margin: 0 auto;
            text-align: center;
        }
    }
}