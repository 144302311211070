.pricing {
    padding: 20px;
    background-color: #000; // Black background color
    
    &__title {
      font-size: 32px;
      margin-bottom: 16px;
      text-align: center;
      color: #fff; // White color for text
    }
  
    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .plan-card {
      background-color: #222; // Dark gray for card background
      border: 1px solid #444; // Slightly lighter gray for the border
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); // Darker shadow for better contrast
      margin: 16px;
      padding: 24px;
      width: 300px;
      text-align: center;
      position: relative;
      transition: transform 0.3s ease;
      color: #fff; // White color for text
  
      &--popular {
        border-color: #ff5722; // Highlight color
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.7);
  
        .plan-card__badge {
          position: absolute;
          top: 16px;
          right: 16px;
          background-color: #ff5722; // Badge color
          color: #fff; // Badge text color
          padding: 8px 16px;
          border-radius: 4px;
          font-weight: bold;
          font-size: 14px;
        }
      }
  
      &__name {
        font-size: 24px;
        margin: 8px 0;
        color: #fff; // White color for text
      }
  
      &__price {
        font-size: 20px;
        margin: 16px 0;
        color: #fff; // White color for text
  
        &-value {
          font-weight: bold;
        }
  
        &-period {
          font-size: 14px;
          color: #aaa; // Light gray for period text
        }
      }
  
      &__features {
        list-style: none;
        padding: 0;
        margin: 16px 0;
        font-size: 14px;
        color: #ddd; // Light gray for feature text
  
        &__feature {
          margin: 8px 0;
          line-height: 1.5;
        }
      }
  
      &__button {
        background-color: #444; // Dark gray for button background
        color: #fff; // White color for button text
        border: none;
        border-radius: 4px;
        padding: 12px 24px;
        font-size: 16px;
        cursor: not-allowed;
        transition: background-color 0.3s ease;
  
        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
  