.footer-homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
  text-align: center;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &__logo {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__about {
    flex: 1;
    margin-bottom: 20px;
    max-width: 600px;

    p {
      margin: 0;
      font-size: 13px;
      line-height: 1.9;
    }

    @media (max-width: 768px) {
      margin-bottom: 20px;
      text-align: center;
    }
  }

  &__auth-buttons {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;

    .footer-homepage__button {
      padding: 10px 20px;
      background-color: #fff;
      color: #333;
      border: none;
      font-size: 1rem;
      cursor: pointer;
      text-decoration: none;
      border-radius: 5px;

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }

  &__policies {
    display: flex;
    flex-wrap: wrap; // Allow wrapping for better layout on smaller screens
    gap: 15px;
    justify-content: center; // Center the links

    .footer-homepage__link {
      color: #fff;
      text-decoration: none;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__version {
    font-size: 0.875rem;
    margin-bottom: 10px;
  }

  &__copyright {
    font-size: 0.875rem;
  }
}
