.cancellation-refund-policy {
  padding: 20px;
  background-color: #000; // Black background
  text-align: center;

  &__title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #fff; // White text for title
  }

  &__content {
    max-width: 800px;
    margin: 0 auto;
    color: #fff; // White text for content

    h2 {
      font-size: 14px;
      margin-top: 24px;
      margin-bottom: 10px;
      color: #fff; // White text for headings
    }

    p {
      font-size: 12px;
      margin-bottom: 16px;
      line-height: 1.5;
      text-align: justify;
    }

    .cancellation-refund-policy__last-updated {
      font-size: 12px;
      margin-top: 20px;
      text-align: center;
      color: #fff;
    }
  }
}
