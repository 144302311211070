.checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 13px 20px 10px;
    background-color: #f9f9f9;

    &__title {
        font-size: 25px;
        margin-bottom: 12px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    &__form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        &--inline {
            flex-direction: row;
            justify-content: space-between;
            input{

                width: 100%;
            }
            & > .checkout__form-group-item {
                flex: 1;
                &:not(:last-child) {
                    
                    margin-right: 10px;
                  
                }
            }
        }
    }

    &__label {
        margin-bottom: 2px;
        font-weight: 300;
    }

    &__input {
        padding: 3.5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
    }

    &__submit-button {
        padding: 10px 15px;
        font-size: 1rem;
        font-weight: bold;
        color: #fff;
        background-color: #000000;
        border: 1px solid black;
        border-radius: 4px;
        cursor: pointer;
        align-self: flex-end;

        &:hover {
            background-color: #ffffff;
            color: black;
            border: 1px solid black;
        }
    }
}
