.category-products-page {

  height: 100vh;
  display: flex;
  flex-direction: column;

  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  // height: calc(100vh - 60px);
  // overflow: hidden;

  .category-breadcrumb {
    margin-bottom: 20px;
    font-size: 14px;

    a {
      color: #007bff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .category-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h1 {
      margin: 0;
      font-size: 24px;
    }

    @media (max-width: 500px) {
      h1 {
        margin: 0;
        font-size: 16px;
      }
    }

    select {
      padding: 5px 10px;
      border-radius: 4px;
    }

    .sidebar-toggle {
      // display: none; 
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
    }
  }

  .category-content-wrapper {
    flex: 1;
    display: flex;
    overflow: hidden;

    .category-sidebar {
      flex: 0 0 200px;
      transition: transform 0.3s ease-in-out;
      overflow-y: auto;

      .sidebar-title {
        margin-top: 0;
        font-size: 18px;
        margin-bottom: 14px;
      }

      .category-list {
        list-style: none;
        padding: 0;

        .category-item {
          margin-bottom: 10px;

          .category-label {
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;

            .category-checkbox {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            .checkbox-custom {
              position: relative;
              display: inline-block;
              width: 18px;
              height: 18px;
              background-color: #fff;
              border: 2px solid #007bff;
              border-radius: 3px;
              margin-right: 10px;

              &:after {
                content: "";
                position: absolute;
                display: none;
                left: 5px;
                top: 1px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
              }
            }

            .category-checkbox:checked~.checkbox-custom:after {
              display: block;
            }

            .category-checkbox:checked~.checkbox-custom {
              background-color: #007bff;
            }

            .category-name {
              font-size: 14px;
            }
          }
        }
      }
    }

    .category-products-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .category-products-grid {
        // flex: 1;
        // display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        // gap: 20px;
        // padding: 20px;
        display: flex;
        flex-wrap: wrap; // Allow items to wrap to the next line
        justify-content: space-between; 


        .loading-spinner,
        .no-products-message {
          grid-column: 1 / -1; // Span all columns
          text-align: center;
          padding: 20px;
          font-size: 18px;
          color: #666;
        }

        .loading-spinner {
          // You can add a spinning animation here if you want
        }

        .no-products-message {
          background-color: #f8f8f8;
          border-radius: 8px;
        }
      }

      .load-more-container {
        padding: 20px;
        text-align: center;

        .category-load-more {
          display: block; // Make the button a block element
          margin: 20px auto; // Center the button horizontally
          padding: 12px 24px; // Add padding for a comfortable touch target
          background-color: #f0f0f0; // Use a light gray background for a simple look
          color: #333; // Dark text for contrast
          border: 1px solid #ccc; // Light border for definition
          border-radius: 8px; // Rounded corners for a modern look
          cursor: pointer;
          transition: background-color 0.3s ease, transform 0.2s ease; // Smooth transitions

          &:hover {
            background-color: #e0e0e0; // Slightly darker gray on hover
            transform: translateY(-2px); // Slight lift effect on hover
          }

          &:active {
            background-color: #d0d0d0; // Even darker on active
            transform: translateY(0); // Reset transform on active
          }
        }
      }
    }
  }
}

// Media queries for responsiveness
@media (max-width: 768px) {
  .category-content-wrapper {
    flex-direction: column;

    .category-sidebar {
      position: fixed;
      top: 170px;
      left: 0;
      width: 250px;
      height: 100%;
      z-index: 1000;
      background-color: white;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      overflow-y: auto;
      padding: 20px;
      box-sizing: border-box;

      &.open {
        transform: translateX(0);
      }

      .sidebar-close {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .sidebar-title {
        margin-bottom: 20px;
      }

      .category-list {
        .category-item {
          margin-bottom: 15px;

          .category-label {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .category-content-wrapper {
    position: relative;
    flex-direction: column;
    overflow: visible;

    .category-sidebar {
      position: fixed;
      top: 170px;
      left: 0;
      // width: 100%;
      height: 100%;
      z-index: 1000;
      background-color: white;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      overflow-y: auto;
      padding: 20px;
      box-sizing: border-box;

      &.open {
        transform: translateX(0);
      }

      .sidebar-close {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .sidebar-title {
        margin-bottom: 20px;
      }

      .category-list {
        .category-item {
          margin-bottom: 15px;

          .category-label {
            font-size: 16px;
          }
        }
      }
    }
  }
}
.category-products-grid > * {
  flex: 0 0 23%; // Each card takes up 23% of the container width on larger screens
  margin-bottom: 10px; // Add some space between rows
}

@media (max-width: 600px) {
  .category-products-grid > * {
      flex: 0 0 48%; // Ensure two cards fit side by side on small screens
  }
}