.orderReport {
    height: 600px;
    width: 100%;
    margin-top: 50px;
    position: relative;
    font-family: Arial, sans-serif;

    &__title {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px 0px 0px;

        svg {
            cursor: pointer;

            &:hover {
                color: gray;
            }
        }
    }

    &__exportExcel {
        display: flex;
        align-items: center;

        .export-button {
            display: flex;
            align-items: center;
            background-color: #000000;
            color: #fff !important;
            border: 1px solid black;
            padding: 10px 15px;
            font-size: 14px;
            cursor: pointer;
            border-radius: 5px;
            transition: background-color 0.3s ease;
            margin-bottom: 10px;

            svg {
                margin-right: 8px;
            }

            &:hover {
                border: 1px solid black;
                background-color: #ffffff;
                color: black !important;
            }
        }
    }

    &__exportModal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        width: 90%;
        max-width: 400px;

        h2 {
            margin-bottom: 20px;
            font-size: 20px;
            color: #333;
        }

        .column-options {
            max-height: 300px;
            overflow-y: auto;
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
            gap: 10px;

            .MuiFormControlLabel-root {
                margin: 0;
            }
        }

        .select-actions {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;

            button {
                padding: 5px 10px;
                background-color: #f0f0f0;
                border: 1px solid #ccc;
                border-radius: 4px;
                cursor: pointer;
                font-size: 14px;
                transition: all 0.3s ease;

                &:hover {
                    background-color: #e0e0e0;
                }
            }
        }

        .modal-actions {
            display: flex;
            justify-content: flex-end;
            gap: 10px;

            button {
                padding: 8px 16px;
                border-radius: 4px;
                cursor: pointer;
                font-size: 14px;
                transition: all 0.3s ease;

                &.export-btn {
                    background-color: #000000;
                    color: white;
                    border: 1px solid #000000;

                    &:hover {
                        background-color: #ffffff;
                        color: #000000;
                    }
                }

                &.cancel-btn {
                    background-color: #ffffff;
                    color: #000000;
                    border: 1px solid #000000;

                    &:hover {
                        background-color: #f0f0f0;
                    }
                }
            }
        }
    }

    &__dateRangeContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 12px;
        position: relative;
        width: 100%;
    }

    &__dateRange {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f5f5f5; // Light grey for modern look
        padding: 10px 15px;
        border-radius: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
        cursor: pointer;
        width: 100%;
        max-width: 400px;
        text-align: center;

        &:hover {
            background-color: #e0e0e0; // Slight color change on hover
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        }

        svg {
            color: rgb(0, 0, 0);
            cursor: pointer;

            &:hover {
                color: gray;
            }
        }
    }

    &__dateRangeText {
        font-size: 16px;
        color: #333;
        font-weight: 500;
    }

    &__selectRangeBtn {
        background-color: #000000;
        color: white;
        border: none;
        padding: 8px 12px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
        // transition: background-color 0.3s ease;
        border: 1px solid rgb(255, 255, 255);

        &:hover {
            background-color: #ffffff;
            color: black;
            border: 1px solid black;
        }
    }

    &__dateRangePickerContainer {
        position: absolute;
        top: 110px;
        left: 0;
        z-index: 10;
        background-color: white;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }

    &__statusFilter {
        margin-bottom: 12px;
        display: flex;
        align-items: center;

        .orderReport__statusLabel {
            margin-right: 10px;
            font-size: 16px;
        }

        .orderReport__statusSelect {
            padding: 5px;
            font-size: 16px;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            cursor: pointer;

            &:focus {
                border-color: #007bff;
            }
        }
    }

    &__loading {
        // text-align: center;
        font-size: 18px;
    }
}

@media (max-width: 500px) {

    .orderReport__exportExcel .export-button{
        padding: 5px 5px;
    }
}