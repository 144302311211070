.navbar {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: rgb(0, 0, 0);

    .left {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 10px;
        padding: 5px;

        img {
            height: 25px;
            cursor: pointer;
            border-radius: 50%;
        }
    }
    
    .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 10px;

        svg {
            cursor: pointer;
            color: white;
            font-size: 32px;
        }
    }

    .center {
        display: flex;
        flex: 5;
        justify-content: center; // Center the search box
        position: relative;
    }

    .search-box {
        display: flex;
        align-items: center;
        position: relative; // Ensure the search results are positioned relative to the search box
        width: 100%; // Ensure the search box takes full width of the center container
        max-width: 400px; // Optional: Set a max width for the search box
    }

    .search-input {
        background: rgba(255, 255, 255, 0.1);
        border: none;
        padding: 8px 10px;
        border-radius: 5px;
        color: #fff;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        width: 100%; // Make the input take full width of the search box

        &:focus {
            outline: none;
        }
    }

    .search-results {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 5px;
        margin-top: 5px;
        list-style: none;
        padding: 0;
        max-height: 200px;
        overflow-y: auto;
        z-index: 10;
        width: 100%; // Match the width of the search box

        li {
            padding: 10px;
            cursor: pointer;

            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }
        }

        .search-result-link {
            text-decoration: none;
            color: inherit;
            display: block;
            width: 100%;

            &:hover {
                // text-decoration: underline;
            }
        }
    }
    
    @media (max-width: 768px) {
        .search-input {
            width: 100%; // Ensure full width on smaller screens
        }

        .center {
            justify-content: center;
        }

        .right {
            justify-content: center;
        }
    }
}