/* Passbook.scss */
.passbook {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin-top: 50px;
    position: relative; // Added for positioning the balance display
    margin-bottom: 50px;
    &__title {
        // text-align: center;
        margin-bottom: 20px;
        color: #333;
    }

    &__balance {
        position: absolute; // Positioning the balance display
        top: 20px; // Adjust as needed
        right: 20px; // Adjust as needed
        font-size: 20px;
        color: #007bff; // Color for the balance
    }

    &__table {
        width: 100%;
        border-collapse: collapse;

        &-header {
            background-color: #000000;
            color: white;
        }

        &-row {
            &:hover {
                background-color: #f1f1f1;
            }
        }

        &-cell {
            padding: 12px;
            text-align: center;
            border-bottom: 1px solid #ddd;

            &--debit {
                color: red;
            }

            &--credit {
                color: green;
            }
        }
    }
}