.cart-page {

    color: #524e4e;
    .cart-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // height: 80%;
    }

    .cart__item {

        display: flex;
        gap: 20px;
        margin: 5px 0px 10px 0px;

        // margin: 20px 0px;
        img {
            width: 60px;
            height: 60px;
            object-fit: contain;
            margin-right: 20px;
        }

        &-details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 4px;
           
        }

        &-name {
            font-size: 15px;
            font-weight: bold;
        }

        &-price {
            font-size: 16px;
        }

        &-discount {
            font-size: 16px;
        }

        &-quantity {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        button {
            background-color: #000000;
            color: white;
            border: none;
            padding: 7px;
            cursor: pointer;
            // transition: all 0.2s ease;

            &:hover {
                background-color: #ffffff;
                border: 2px solid rgba(0, 0, 0, 0.8);
                color: black;
            }
        }
    }

    .cart__remarks {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f7f7f7;
        padding: 10px 15px;
        font-weight: 500;
    }

    .cart__subtotal {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        margin-bottom: 10px;
        width: 100%;
    }

    .cart__button {
        width: 100%;

        .checkout-btn{
            width: 100%;
            width: 100%;
            height: 29px;
            border-radius: 5px;
            color: white;
            background-color: #000000;
            margin-top: 10px;
            border: none;
            cursor: pointer;
            height: 40px;
            border: 1px solid black;

            &:hover {
                background-color: #ffffff;
                border: 1px solid rgba(0, 0, 0, 0.8);
                color: black;
            }
        }
    }

    .cart__subtotal div:first-child {
        font-weight: bold;
    }

    .cart__subtotal div:last-child {
        font-weight: normal;
    }

    .cart-panel.hide {
        right: -415%;
    }

    .cart-panel {
        position: fixed;
        top: 0;
        right: 0;
        // width: 75%;
        height: 100%;
        background-color: white;
        z-index: 999;
        display: flex;
        flex-direction: column;
        // padding: 10px 15px;
        transition: all 0.5s ease-in-out;
        width: 450px;
        max-width: 100%;

        &-items {
            height: 80%;
            padding: 10px 15px;

            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: #ccc #f7f7f7;

            margin-right: 10px;

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background-color: #f7f7f7;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #ccc;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #999;
            }
        }

        &-header {
            display: flex;
            justify-content: space-between;
            padding: 10px 15px;

            h1 {
                font-weight: 500;
                font-size: 25px;
            }

            svg {
                fill: #000000 !important;
            }
        }


        .cart-panel-content {
            text-align: center;

            h2 {
                font-size: 2rem;
                margin-bottom: 20px;
            }

            button {
                background-color: #008cff;
                color: white;
                border: none;
                padding: 10px;
                cursor: pointer;
                transition: all 0.2s ease;

                &:hover {
                    background-color: #0075ff;
                }
            }
        }
    }
}

.cart-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;

    img {
        max-width: 150px;
        height: auto;
    }

    p {
        font-size: 18px;
        font-weight: bold;
        margin-top: 20px;
    }
}

.cart-panel.show {
    right: 0;
}
.empty-cart-message {
    color: red;
    font-size: 0.9em;
    text-align: center;
    margin-top: 10px;
  }

@media screen and (max-width: 500px) {

    .cart-page .cart__item-name {
        font-size: 12px;
        font-weight: 400;
    }
}

.primaryButton-outline {
    width: 100%;
    height: 40px;
    background-color: rgb(255, 255, 255);
    color: black!important;
    border: 1px solid black;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
        // background-color: rgba(236, 240, 234, 0.445);
        border: 2px solid black;
        // transform: scale(1.01);
        // color: white!important;
    }
    
}

.inventory-warning {
    background-color: rgba(255, 165, 0, 0.1);
    color: #ff8c00;
    border: 1px solid rgba(255, 165, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 5px;
}

.inventory-warning::before {
    content: "⚠️";
    font-size: 1.2rem;
}

.cart__item-name {
    display: flex;
    align-items: center; // Aligns the text and note vertically
}

.prebook-note {
    font-size: 0.9rem; // Slightly smaller font size
    color: #ff9800; // Orange color for visibility
    margin-left: 5px; // Space between product name and note
    font-weight: bold; // Make the note bold
}