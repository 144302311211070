.orderAnalytics {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Arial', sans-serif;
  margin-top: 25px;

  &__container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__header {
    margin-bottom: 30px;
    
    display: flex;
    flex-direction: column;
    align-items: baseline;

    h2 {
      font-size: 28px;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  &__dateRange {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    span {
      font-size: 16px;
      cursor: pointer;
      padding: 8px 12px;
      border: 1px solid #ddd;
      border-radius: 4px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #f5f5f5;
      }
    }

    button {
      background-color: #000;
      color: #fff;
      border: none;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 4px;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.8;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  &__dateRangePicker {
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  &__error {
    background-color: #ffebee;
    color: #d32f2f;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__content {
    flex-grow: 1;

    h3 {
      font-size: 24px;
      margin-bottom: 20px;
      // text-align: center;
    }
  }

  &__summary {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
    justify-content: center;

    .summary-card {
      flex: 1;
      max-width: 250px;
      padding: 20px;
      border-radius: 8px;
      text-align: center;
      color: #fff;
      background: linear-gradient(135deg, #ff7e5f, #feb47b); /* Example gradient */
      transition: transform 0.3s, box-shadow 0.3s;
    }

    .summary-card:nth-child(2) {
      background: linear-gradient(135deg, #6a11cb, #2575fc); /* Example gradient */
    }

    .summary-card:nth-child(3) {
      background: linear-gradient(135deg, #43cea2, #185a9d); /* Example gradient */
    }

    .summary-card:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    h4 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    p {
      font-size: 24px;
      font-weight: bold;
    }
  }

  &__productSales {
    h3 {
      margin-bottom: 15px;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }

      th {
        background-color: #f5f5f5;
        font-weight: bold;
      }

      tr:hover {
        background-color: #f9f9f9;
      }
    }
  }
}



// ... existing code ...

@media (max-width: 600px) {
  .orderAnalytics {
    margin-top: 25px;
  }

  .orderAnalytics__container {
    padding: 10px;
  }

  .orderAnalytics__header h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .orderAnalytics__dateRange {
    // flex-direction: column;
    gap: 10px;
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    max-width: 100%; /* Ensure it doesn't exceed the screen width */
  }

  .orderAnalytics__dateRange span,
  .orderAnalytics__dateRange button {
    font-size: 14px;
    padding: 8px 10px;
    white-space: nowrap; /* Prevent text from wrapping */
  }

  .orderAnalytics__summary {
    flex-direction: column;
    gap: 15px;
  }

  .orderAnalytics__summary .summary-card {
    max-width: 100%;
    padding: 15px;
  }

  .orderAnalytics__content h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .orderAnalytics__productSales table th,
  .orderAnalytics__productSales table td {
    padding: 8px;
    font-size: 14px;
  }
}