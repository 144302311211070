* {
    margin: 0px;
    padding: 0%;
    box-sizing: border-box;
    /* overflow: hidden; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif; 

}
.inputboxTwo{
    border: 1px solid #cccc;
    /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); */
    height: 33px;
    outline: none;
    width: 50%;
    padding: 0 10px;
    border-radius: 4px;
    padding: 20px 10px;
}
.actionButton-navbar{
    align-items: center;
    background-color: transparent; 
    position: fixed;
    top: 10px;
    right: 35px;
    border: 2px solid #fff; 
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    transition: all 0.3s ease-out;
    width: 98px;
    z-index: 11;
    
}
.button-delete{
    background-color: red;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    /* height: 10px; */
    padding: 4px 11px;
}
.button-cancel{
    background-color: #ffb04d;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    /* height: 10px; */
    padding: 4px 11px;
}
.button-duplicate{
    background-color:#838380;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    /* height: 10px; */
    padding: 4px 11px;
}
.button-submit{
    
}

.button-primary{
    align-items: center;
    background-color: black;
    border: 2px solid #fff;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    transition: all 0.2s ease-out;
    width: 138px;
    font-weight: 400;

    &:hover {
        background-color: #ffffff;
        border: 2px solid #000000;
        color: black;
    }
}