.confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it appears above other content */

    .confirmation-box {
        background-color: white;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        width: 400px;
        /* Adjust width as needed */
        // text-align: center;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 90%;
        // align-items: center; /* Center align items */
    }

    h2 {
        font-size: 18px;
    }

    p {
        margin: 0 0 20px;
        color: #333;
        /* Adjust text color */
        text-align: justify;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        /* Align buttons to the right */
        gap: 10px;
        width: 100%;
        /* Ensure it takes full width */
    }

    .cancel-button {
        background-color: white;
        /* White background for cancel */
        color: #f44336;
        /* Red text color for cancel */
        border: 1px solid #f44336;
        /* Red border for cancel */
        border-radius: 4px;
        padding: 10px 15px;
        cursor: pointer;
    }

    .confirm-button {
        background-color: #f44336;
        /* Red background for confirm */
        color: white;
        border: none;
        border-radius: 4px;
        padding: 10px 25px;
        cursor: pointer;
    }

    .cancel-button:hover,
    .confirm-button:hover {
        opacity: 0.8;
        /* Slightly fade on hover */
    }
}