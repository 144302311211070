.privacy-policy {
    padding: 20px;
    background-color: #000; // Black background color
    
    &__title {
      font-size: 24px; // Adjusted for better readability
      margin-bottom: 20px;
      color: #fff; // White color for title
      text-align: center;
    }
  
    &__content {
      max-width: 800px;
      margin: 0 auto;
      color: #fff; // White color for text
      text-align: center;
      h2 {
        font-size: 14px; // Heading font size
        margin-top: 24px;
        margin-bottom: 10px;
        color: #fff; // White color for section headings
      }
  
      p {
        font-size: 12px; // Content font size
        margin-bottom: 16px;
        line-height: 1.5;
        text-align: justify;
      }
  
      ul {
        margin: 16px 0;
        padding-left: 20px;
  
        li {
          font-size: 12px; // Font size for list items
          margin-bottom: 8px;
        }
      }
    }
  }
  