.info-box {
    background-color: #f0f4f8;
    border-left: 4px solid #3498db;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 0 4px 4px 0;

    .MuiTypography-root {
        font-size: 0.9rem;
        line-height: 1.4;
    }

    .MuiSvgIcon-root {
        font-size: 1.1rem;
        vertical-align: middle;
    }
}