.navbarHomepage {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    align-items: center;

    &__logo {
        font-size: 1.5rem;
        font-weight: bold;
    }

    &__auth-buttons {
        display: flex;
    }

    &__button {


        &-login {
            margin-left: 1rem;
            padding: 0.5rem 1rem;
            border: none;
            cursor: pointer;
            background-color: #000;
            color: #fff;
            font-size: 1rem;
            border-radius: 5px;
            // background-color: #333;

            &:hover {
                background-color: white;
                color: black;
                border: 1px solid black;
            }
        }

        &-signup {
            // background-color: #333;
            margin-left: 1rem;
            padding: 0.5rem 1rem;
            border: none;
            cursor: pointer;
            background-color: #ffffff;
            color: #000000;
            font-size: 1rem;
            border-radius: 5px;
            border: 1px solid black;

            &:hover {
                background-color: rgb(0, 0, 0);
                color: rgb(255, 255, 255);
                border: 1px solid rgb(255, 255, 255);
            }
        }
    }

}

@media (max-width: 400px) {

    .navbarHomepage {
        padding: 12px 6px;

        &__logo {
            font-size: 20px;
        }

        button {
            padding: 5px 10px;
        }
    }

}