
.offlineOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .offlineOverlay_message {
    background-color: #444;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  