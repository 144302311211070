// AboutUs.scss
.about-us {
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    background-color: black;
    &__hero {
    //   background-color: #f5f5f5;
      padding: 40px 20px;
      text-align: center;
    //   border-bottom: 1px solid #ddd;
    }
  
    &__title {
      font-size: 24px;
      color: #ffffff;
    }
  
    &__subtitle {
      font-size: 14px;
      color: #777;
    }
  
    &__content {
      display: flex;
      justify-content: space-around;
      margin-top: 40px;
    }
  
    &__block {
      width: 30%;
      padding: 20px;
    //   background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
      &--mission {
        border-left: 4px solid #3498db;
      }
  
      &--vision {
        border-left: 4px solid #2ecc71;
      }
  
      &--values {
        border-left: 4px solid #e74c3c;
      }
    }
  
    &__block-title {
      font-size: 20px;
      margin-bottom: 10px;
      color: #ffffff;
    }
  
    &__block-text {
      font-size: 14px;
      color: #9a9696;
    }
  
    &__list {
      list-style: none;
      padding: 0;
      margin: 20px 0 0;
  
      &-item {
        font-size: 1rem;
        color: #9a9696;
        margin-bottom: 8px;
      }
    }
  
    &__cta {
      text-align: center;
      margin-top: 40px;
      padding: 40px 20px;
      background-color: #000000;
      color: #fff;
  
      &-text {
        font-size: 1.25rem;
      }
  
      &-button {
        display: inline-block;
        padding: 10px 20px;
        background-color: #fff;
        color: #000000;
        border-radius: 4px;
        text-decoration: none;
        font-weight: 600;
        margin-top: 20px;
      }
    }
  }
  