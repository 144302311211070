.package-dimensions-form {
    flex: 1;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    // margin-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    svg {
        cursor: pointer;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        .add-icon {
            cursor: pointer;
            font-size: 24px;
            color: #000;
            transition: color 0.3s;

            &:hover {
                color: #555;
            }
        }
    }

    &__label {
        font-size: 14px;
        margin-bottom: 5px;
        font-weight: bold;
    }

    &__select {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-bottom: 15px;
    }

    &__details {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }

    &__group {
        display: flex;
        flex-direction: column;
    }

    &__input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
    }

    &__update-button {
        padding: 10px 20px;
        background-color: #000;
        color: #fff;
        border-radius: 4px;
        border: 1px solid #000;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s;

        &:hover {
            color: #000;
            background-color: #fff;
            border-color: #000;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0.3s;

        &--active {
            opacity: 1;
            visibility: visible;
        }

        .new-package-form {
            background-color: #fff;
            padding: 30px;
            border-radius: 8px;
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
            width: 400px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            position: relative;

            h3 {
                margin-bottom: 20px;
            }

            button {
                padding: 10px 20px;
                background-color: #000;
                color: #fff;
                border-radius: 4px;
                border: 1px solid #000;
                cursor: pointer;
                transition: background-color 0.3s, color 0.3s, border-color 0.3s;
               
                &:hover {
                    color: black;
                    background-color: white;
                    border: 1px solid black;
                }
            }

            .close-icon {
                position: absolute;
                top: 15px;
                right: 15px;
                font-size: 24px;
                cursor: pointer;
                color: #333;
                transition: color 0.3s;

                &:hover {
                    color: #555;
                }
            }
        }
    }

    &__delete-button {
        background: none;
        border: none;
        color: #ff0000;
        cursor: pointer;
        font-size: 24px;
        transition: color 0.3s;

        &:hover {
            color: #cc0000;
        }

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .new-package-form {
        .close-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 24px;
            cursor: pointer;
            color: #333;
            transition: color 0.3s;

            &:hover {
                color: #555;
            }
        }
    }
}
