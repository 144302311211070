.order-dismissed-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  padding: 1rem;

  .overlay-content {
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    max-width: 28rem;
    width: 100%;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    position: relative;

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      cursor: pointer;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.2s ease;

      svg {
        fill: #666;
        transition: fill 0.2s ease;
      }

      &:hover {
        transform: scale(1.1);

        svg {
          fill: #333;
        }
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
        border-radius: 50%;
      }
    }
  }

  .content-wrapper {
    text-align: center;
  }

  .error-icon {
    width: 5rem;
    height: 5rem;
    margin: 0 auto 1.5rem;
    background-color: #fee2e2;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 2.25rem;
      font-weight: bold;
      color: #dc2626;
    }
  }

  h2 {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #1f2937;
  }

  .error-message {
    color: #4b5563;
    margin-bottom: 2rem;
  }

  .order-details {
    background-color: #f9fafb;
    border-radius: 0.75rem;
    padding: 1rem;
    margin-bottom: 2rem;

    h3 {
      font-weight: 600;
      font-size: 1.125rem;
      margin-bottom: 0.5rem;
      color: #374151;
    }

    p {
      color: #4b5563;
    }
  }

}


.retry-message {
  margin-top: 15px; // Add some space above the message
  color: #d9534f; // Use a red color to indicate a warning or important message
  font-size: 14px; // Set a readable font size
  text-align: center; // Center the text
  background-color: #f8d7da; // Light red background for emphasis
  padding: 10px; // Add padding for better spacing
  border-radius: 5px; // Rounded corners for a softer look
  border: 1px solid #f5c6cb; // Border to match the background color
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
}

.retry-button {
  background-color: #4CAF50; // Green background
  color: white; // White text
  padding: 12px 24px; // Padding for size
  font-size: 16px; // Font size
  border: none; // Remove border
  border-radius: 8px; // Rounded corners
  cursor: pointer; // Pointer cursor on hover
  transition: background-color 0.3s ease, transform 0.2s ease; // Smooth transitions
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Subtle shadow

  &:hover:not(:disabled) {
      background-color: #45a049; // Darker green on hover
      transform: translateY(-2px); // Slight lift on hover
  }

  &:active {
      background-color: #3e8e41; // Even darker green on click
      transform: translateY(0); // Reset lift on click
  }

  &:disabled {
      cursor: not-allowed; // Change cursor to indicate non-clickable
      background-color: #ccc; // Change background to indicate disabled state
      color: #666; // Change text color for disabled state
  }

  &:focus {
      outline: none; // Remove default outline
      box-shadow: 0 0 0 3px rgba(72, 187, 120, 0.5); // Custom focus outline
  }
}