.product-card4 {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
  height: 300px;
  width: 212px;
  &:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }

  a {
    text-decoration: none;
  }

  .image-container {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 15px;

    img {
      width: 100%;
      height: 150px;
      object-fit: contain;
      border-radius: 8px;
      transition: transform 0.3s ease;

    }

    &:hover img {
      transform: scale(1.05);
    }

    .discount-badge {
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: #ff4d4f;
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  .product-info {
    .product-title {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 8px;
      color: #333;
    }

    .product-price {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .current-price {
        font-size: 1.25rem;
        font-weight: 600;
        color: #007bff;
        margin-right: 10px;
      }

      .original-price {
        font-size: 1rem;
        text-decoration: line-through;
        color: #888;
      }
    }
  }
}

@media (max-width: 600px) {
  .product-card4 {
      flex: 0 0 48%; // Ensure two cards fit side by side on small screens
  }
}