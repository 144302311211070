.terms-conditions {
  padding: 20px;
  background-color: #000; // Black background color
  text-align: center;

  &__title {
    font-size: 24px; // Adjusted for better readability
    margin-bottom: 20px;
    color: #fff; // White color for title
    text-align: center;
  }

  &__content {
    max-width: 800px;
    margin: 0 auto;
    color: #fff; // White color for text

    h2 {
      font-size: 14px; // Heading font size
      margin-top: 24px;
      margin-bottom: 10px;
      color: #fff; // White color for section headings
    }

    p {
      font-size: 12px; // Content font size
      margin-bottom: 16px;
      line-height: 1.5;
      text-align: justify;
    }

    ul {
      margin-bottom: 16px;
      padding-left: 20px;

      li {
        font-size: 12px;
        color: #fff; // White text color for list items
        margin-bottom: 8px;
        line-height: 1.5;
        text-align: left;
      }
    }

    &__last-updated {
      font-size: 12px; // Last updated font size
      margin-top: 20px;
      text-align: center;
      color: #fff;
      text-align: justify;
    }
  }
}
