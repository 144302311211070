@import '../../../styles/common.scss';

.payment-settings-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin-top: 50px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }


    &__title {
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: bold;
    }

    &__section {
        // margin-bottom: 20px;
        // border: 1px solid #80808042;
        // border-radius: 5px;
        // padding: 10px;

        position: relative;
        padding: 20px;
        margin-bottom: 20px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff;
        transition: all 0.3s ease;
        overflow: hidden; // Ensure the overlay is contained within the section

        .payment-settings-form__content {

            // Apply blur only to the content
            &.blurred {
                filter: blur(5px);
                pointer-events: none; // Disable interactions
            }
        }

        .access-denied-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            font-size: 1.2rem;
            text-align: center;
            border-radius: 8px;
            pointer-events: all;
            z-index: 10;


            p {
                color: rgb(0, 0, 0);
                font-size: 16px;
                font-weight: 500;
            }

            .upgrade-button {
                margin-top: 10px;
                background-color: #007bff;
                color: #fff;
                border: none;
                padding: 10px 20px;
                font-size: 1rem;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #0056b3;
                }
            }
        }

        &-title {
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: bold;

            h6 {
                font-size: 12px;
                font-weight: 400;
                color: green;
            }
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        p {
            color: gray;
            font-size: 12px;
            margin: 5px 0;
        }
    }

    &__field {
        margin-bottom: 15px;
    }

    &__field-group {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        /* Optional, adds spacing between fields */
    }

    &__label {
        font-size: 14px;
        margin-bottom: 5px;
        font-weight: bold;
        display: block;
    }

    &__checkbox {
        margin-right: 10px;
    }

    &__input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 100%;
        /* Set width for input fields */
        box-sizing: border-box;
    }

    &__save-button {
        padding: 10px 20px;
        background-color: #000;
        color: #fff;
        border-radius: 4px;
        border: 1px solid #000;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s;

        &:hover {
            color: #000;
            background-color: #fff;
            border-color: #000;
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 34px;
        height: 20px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: 0.4s;
            border-radius: 34px;
        }

        .slider:before {
            position: absolute;
            content: '';
            height: 14px;
            width: 14px;
            left: 3px;
            bottom: 3px;
            background-color: white;
            transition: 0.4s;
            border-radius: 50%;
        }

        input:checked+.slider {
            background-color: #000000;
        }

        input:checked+.slider:before {
            transform: translateX(14px);
        }
    }

    // Add styles for the toggle container
    .toggle-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .toggle-label {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 500;
        }
    }

    // Add styles for the account ID display
    .account-id {
        font-size: 14px;
        color: #666;
        margin-left: 44px;
        margin-top: 5px;
    }

    &__razorpay-status {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__section-subtitle {
        font-size: 12px;
        font-weight: 400;
        color: #666;

        &.connected {
            color: #28a745;
        }
    }

    &__disconnect-icon-wrapper {
        position: relative;
        display: inline-block;
        cursor: pointer;

        .tooltip {
            visibility: hidden;
            width: 120px;
            background-color: #555;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 1;
            bottom: 125%;
            left: 50%;
            margin-left: -60px;
            opacity: 0;
            transition: opacity 0.3s;
            font-size: 12px;
        }

        .tooltip::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #555 transparent transparent transparent;
        }

        &:hover .tooltip {
            visibility: visible;
            opacity: 1;
        }
    }

    &__disconnect-icon {
        width: 20px;
        height: 20px;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 0.7;
        }
    }

    &__connect-razorpay-button {
        padding: 8px 16px;
        background-color: #000;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #333;
        }
    }

    &__razorpay-integration {
        margin-top: 30px;
        padding: 25px;
        background-color: #f8f9fa;
        border-radius: 12px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    }

    &__integration-title {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 600;
        color: #333;
    }

    &__integration-mode {
        margin-bottom: 20px;
        padding: 20px;
        border-radius: 8px;
        transition: all 0.3s ease;

        &--live {
            background-color: #ffffff;
            border: 1px solid #e0e0e0;
        }

        &--test {
            background-color: #f0f8ff; // Light blue background for test mode
            border: 1px solid #b3d9ff;
        }

        &:hover {
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
        }
    }

    &__mode-title {
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: 500;
        color: #444;
    }

    &__razorpay-status {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__connected-status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__account-id {
        font-size: 14px;
        color: #1a73e8;
        font-weight: 500;
    }

    &__connect-button,
    &__disconnect-button {
        padding: 10px 16px;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    &__connect-button {
        background-color: #1a73e8;
        color: white;

        &:hover {
            background-color: #1557b0;
        }
    }

    &__disconnect-button {
        background-color: #f44336;
        color: white;

        &:hover {
            background-color: #d32f2f;
        }
    }

    .info-box {
        margin-bottom: 20px;

        ul {
            margin-top: 10px;
            padding-left: 20px;

            li {
                font-size: 0.9rem;
                margin-bottom: 8px;

                .warning-text {
                    display: block;
                    // color: #ff6b6b;
                    // font-weight: bold;
                    font-style: italic;
                    margin-top: 4px;
                }
            }
        }
    }
}

.payment-settings-form__connect-razorpay-button {
    background-color: #080808;
    /* Razorpay blue */
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
}

.payment-settings-form__connect-razorpay-button:hover {
    background-color: #404142;
    /* Darker blue for hover effect */
}

.payment-settings-form__connect-razorpay-button:active {
    background-color: #3a3a3b;
    /* Even darker blue when active */
}

.payment-settings-form__connect-razorpay-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.payment-settings-form__connect-razorpay-button .icon {
    margin-right: 8px;
    /* Space between icon and text */
}



@media (max-width: 600px) {
    .payment-settings-form__connected-status {
        gap: 10px;
    }

    .payment-settings-form__razorpay-status {
        flex-direction: column;
    }

    .payment-settings-form__field-group {
        flex-direction: column;
    }
}