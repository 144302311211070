// seller/src/components/FeatureAccessDenied/featureAccessDenied.scss

.feature-access-denied {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px); // Adjust 60px to the actual height of your navbar
    background-color: #f9f9f9;
    color: #333;
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 20px;
    transition: all 0.3s ease;
  
    h2 {
      font-size: 2rem;
      margin-bottom: 10px;
      color: #ff4c4c;
    }
  
    p {
      font-size: 1.2rem;
      margin-bottom: 20px;
      color: #666;
    }
  
    button {
      background-color: #007bff;
      color: #fff;
      border: none;
      padding: 10px 20px;
      font-size: 1rem;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }