.policy-editor-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .policy-editor {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;

    h2 {
      margin-bottom: 20px;
    }

    .quill {
      height: 300px;
      margin-bottom: 20px;

      .ql-container {
        height: 250px;
      }
    }

    .editor-actions {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 10px;
        background-color: #000000;
        color: white;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        border: 1px solid #000000;
        transition: all 0.3s ease;
        padding: 10px 40px;

        &:hover {
          background-color: #ffffff;
          color: #000000;
          border: 1px solid #000000;
        }
      }

      .cancel-button {
        background-color: white;
        border: 1px solid black;
        color: black;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
          background-color: #e0dfdf;
          /* Slightly darker on hover */
        }
      }
    }
  }
}