// MessagePlatform.scss
.message-platform {
    display: flex;
    height: 90vh;
    margin-top: 50px;
  
    .contact-list {
      width: 30%;
      border-right: 1px solid #ccc;
  
      input {
        width: 100%;
        padding: 8px;
      }
  
      ul {
        list-style-type: none;
        padding: 0;
  
        li {
          padding: 10px;
          cursor: pointer;
          border-bottom: 1px solid #ccc;
        }
      }
    }
  
    .message-viewer {
      width: 70%;
      padding: 10px;
      background-image: url('./Assets/WA-bg.png');
      background-size: cover;
      display: flex;
      flex-direction: column;
  
      .header {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
      }
  
      .messages {
        list-style-type: none;
        padding: 0;
        flex-grow: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
  
        .message {
          margin-bottom: 10px;
          max-width: 60%;
          padding: 10px;
          border-radius: 10px;
  
          &.right {
            background-color: #dcf8c6;
            align-self: flex-end;
            text-align: right;
          }
  
          &.left {
            background-color: #fff;
            align-self: flex-start;
            text-align: left;
          }
        }
      }
  
      .message-input {
        display: flex;
        align-items: center;
        border-top: 1px solid #ccc;
        padding-top: 10px;
  
        input {
          flex: 1;
          padding: 10px;
          border-radius: 20px;
          border: 1px solid #ccc;
        }
  
        button {
          margin-left: 10px;
          background: none;
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }
  }