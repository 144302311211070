.category-management {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-top: 50px;

  &__title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 16px;
    color: #333;
  }

  &__select {
    //   width: 100%;
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  &__input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;

    &:focus {
      outline: none;
      border-color: #000000;
      border: 1px solid #000000;
    }
  }

  &__image-upload {
    grid-column: 1 / -1;
    margin-top: 16px;

    &-input {
      display: none;
    }

    &-label {
      display: inline-flex;
      align-items: center;
      padding: 8px 16px;
      background-color: #f0f0f0;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #e0e0e0;
      }

      svg {
        margin-right: 8px;
      }
    }

    &-preview {
      margin-top: 16px;
      max-width: 100%;
      max-height: 200px;
      object-fit: contain;
    }
  }

  &__actions {
    grid-column: 1 / -1;
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    margin-top: 24px;
  }

  &__button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 1rem;

    &--primary {
      background-color: #000000;
      color: white;
      border: 1px solid #ffffff;

      &:hover {
        background-color: #ffffff;
        color: #000000;
        border: 1px solid #000000;
      }
    }

    &--secondary {
      background-color: #f44336;
      color: white;

      &:hover {
        background-color: #d32f2f;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 500;
    color: #333;
    margin: 0;
  }

  &__add-button {
    background-color: transparent;
    color: #1976d2;
    border: none;
    padding: 0;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1565c0;
    }
  }

  &__image-preview {
    width: 250px;
    height: 250px;
    border: 2px dashed #ccc;
    border-radius: 4px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #f5f5f5;

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-icon {
      font-size: 48px;
      color: #999;
    }
  }

  &__image-error {
    margin-top: 8px;
    max-width: 400px;
    color: red;
  }

  ul {
    list-style-type: none;
  }

  .info-box {
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 20px;

    ul {
      margin-top: 8px;
      padding-left: 20px;
      list-style-type: disc;

      li {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 1.5;

        strong {
          font-weight: 600;
        }
      }
    }
  }
}