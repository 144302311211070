.subscription-plans {
  padding: 50px 15px;
  background-color: #f2f2f2;
  text-align: center;

  &__title {
    font-size: 32px;
    margin-bottom: 30px;
    color: #333;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8); // Semi-transparent background
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; // Ensure it appears above other content
  }
  
  .loader {
    font-size: 1.5rem; // Adjust size as needed
    color: #000; // Loader text color
  }

  .confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    &__content {
      background: white;
      padding: 30px;
      border-radius: 10px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      text-align: center;
      width: 400px;
      transition: transform 0.3s ease;
      transform: translateY(-20px);
      opacity: 0;
      animation: fadeIn 0.3s forwards;

      h2 {
        margin-bottom: 20px;
        font-size: 24px;
        color: #333;
      }

      h3 {
        margin: 10px 0;
        font-size: 20px;
        color: #555;
      }

      p {
        margin: 5px 0;
        font-size: 16px;
        color: #666;
      }

      .confirmation-modal__message {
        margin-top: 15px;
        font-size: 16px;
        color: #333;
        font-weight: bold;
      }
    }

    &__actions {
      margin-top: 20px;
      margin-top: 20px;
      /* gap: 10px; */
      display: flex;
      /* margin-left: 10px; */
      justify-content: center;
      gap: 10px;

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;


        &.btn-cancel {
          background-color: white; // White background for cancel button
          color: #f44336; // Red text color
          border: 1px solid #f44336; // Red border

          &:hover {
            background-color: #f44336; // Red background on hover
            color: white; // White text on hover
          }
        }

        &.btn-confirm {
          background-color: #4caf50; // Green
          color: white;

          &:hover {
            background-color: #388e3c; // Darker green on hover
          }
        }
      }
    }
  }

  .subscription-details {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1.5rem;
    margin: 1.5rem 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      color: #007bff;
    }

    p {
      margin: 0.5rem 0;
      font-size: 1rem;
      color: #555;

      strong {
        color: #333;
      }
    }
  }
  .plan-card__price-value--strikethrough {
    text-decoration: line-through;
    color: red; // Optional: Change color to indicate it's a strikethrough
    font-size: 20px;
  }


  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

}

.plan-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  width: 300px;
  padding: 25px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s;

  &__special-offer {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: #ffcc00; // Bright yellow for visibility
    color: #000;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1; // Ensure it appears above other elements
  }

  &__badge {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: #ff8c00;
    color: #fff;
    padding: 7px 12px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: bold;
  }

  &__name {
    font-size: 26px;
    margin-bottom: 15px;
    color: #333;
  }

  &__price {
    font-size: 28px;
    margin-bottom: 25px;
    color: #333;

    &-value {
      font-weight: bold;
    }

    &-period {
      font-size: 16px;
      color: #777;
    }
  }

  &__features {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 25px;
    text-align: left;

    & .plan-card__feature {
      margin-bottom: 12px;
      font-size: 15px;
      color: #555;
      padding-left: 20px;
      position: relative;

      &::before {
        content: '✔';
        position: absolute;
        left: 0;
        color: #4caf50;
      }
    }
  }

  &__button {
    background-color: #000000;
    color: #fff;
    border: none;
    padding: 12px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #ffffff;
      color: black;
      border: 1px solid black;
    }

    &:disabled {
      background-color: #ffffff;
      color: #000000;
      cursor: not-allowed;
      border: 1px solid black;
    }
  }

  &--popular {
    border: 2px solid #ff8c00;
    transform: scale(1.08);
    margin: 20px 0px;
  }

  &--current {

    // border: 2px solid #4caf50;
    .plan-card__badge {
      background-color: #4caf50;
    }
  }
}

// Media Queries for Responsiveness

@media (max-width: 992px) {
  .plan-card {
    width: 48%;
  }
}

@media (max-width: 768px) {
  .plan-card {
    width: 90%;
  }
}