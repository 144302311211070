// SellerDomainManager.scss

$primary-color: #007bff;
$secondary-color: #6c757d;
$error-color: #dc3545;
$success-color: #28a745;
$background-color: #f8f9fa;
$border-radius: 4px;

.seller-domain-manager {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: $background-color;
    // border: 1px solid $secondary-color;
    border-radius: $border-radius;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;

    &__title {
        text-align: center;
        color: $primary-color;
    }

    &__domain-list {
        list-style-type: none;
        padding: 0;

        &-item {
            padding: 10px;
            border-bottom: 1px solid $secondary-color;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__input-container {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        &-input {
            padding: 10px;
            border: 1px solid $secondary-color;
            border-radius: $border-radius;
            margin-bottom: 10px;
            font-size: 16px;

            &:focus {
                border-color: $primary-color;
                outline: none;
            }
        }

        &-button {
            padding: 10px;
            background-color: $primary-color;
            color: white;
            border: none;
            border-radius: $border-radius;
            font-size: 16px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: darken($primary-color, 10%);
            }

            &:disabled {
                background-color: $secondary-color;
                cursor: not-allowed;
            }
        }
    }

    &__error-message {
        color: $error-color;
        text-align: center;
        margin-top: 10px;
    }

    &__success-message {
        color: $success-color;
        text-align: center;
        margin-top: 10px;
    }
}