/* Communications.scss */
.communications {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    max-width: 950px;
    margin-bottom: 20px;

    .info-box {
        background-color: #f8f9fa;
        border: 1px solid #e9ecef;
        border-radius: 4px;
        padding: 16px;
        margin-bottom: 20px;

        ul {
            margin-top: 8px;
            padding-left: 20px;
            list-style-type: disc;

            li {
                margin-bottom: 8px;
                font-size: 14px;
                line-height: 1.5;

                strong {
                    font-weight: 600;
                }
            }
        }
    }

    &__title {
        font-size: 24px;
        margin-bottom: 20px;
        color: #333;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        &-alert-list {
            flex: 2;
            margin-right: 20px;

            &-item {
                margin: 10px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__info {
                    display: flex;
                    flex-direction: column;
                    margin-right: 20px;

                    &__label {
                        font-size: 16px;
                        font-weight: bold;

                        .recommended-note {
                            font-size: 12px;
                            color: #12c107;
                            margin-left: 5px;
                            font-weight: 400;
                        }
                    }

                    &__description {
                        font-size: 12px;
                        color: #666;
                    }
                }

                &__buttons {
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    &__add-button,
                    &__preview-button {
                        padding: 10px 20px;
                        height: 40px;
                        border: none;
                        border-radius: 5px;
                        cursor: pointer;
                        transition: background-color 0.3s;
                        font-size: 14px;
                        min-width: 100px;

                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    &__add-button {
                        background-color: #007BFF;
                        color: white;

                        &:hover {
                            background-color: #0056b3;
                        }
                    }

                    &__preview-button {
                        background-color: #6c757d;
                        color: white;

                        &:hover {
                            background-color: #5a6268;
                        }
                    }

                    &__alert-added {
                        color: green;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;

                        .communications__content-alert-list-item__alert-remove-button {
                            margin-left: 5px;
                            background: none;
                            border: none;
                            color: red;
                            cursor: pointer;
                            font-weight: bold;
                        }
                    }

                    &__alert-remove-button {
                        margin-left: 5px;
                        background: none;
                        border: none;
                        color: red;
                        cursor: pointer;
                        font-weight: bold;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    &__summary {
        margin-top: 20px;
        flex: 1;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 12px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

        &-title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        &-item {
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
            border-bottom: 1px solid #e0e0e0;

            &:last-child {
                border-bottom: none;
            }
        }

        &-total {
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            margin-top: 10px;
        }

        &-note {
            margin-top: 10px;
            font-size: 12px;
            color: #666;
        }
    }

    &__update-button {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: #28a745;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: #218838;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        &-content {
            background: white;
            padding: 30px;
            border-radius: 12px;
            width: 400px;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
            transition: transform 0.3s ease;
            transform: scale(0.95);

            &:hover {
                transform: scale(.97);
            }

            &-title {
                font-size: 22px;
                margin-bottom: 15px;
                color: #333;
            }

            &-message {
                margin-bottom: 20px;
                font-size: 14px;
                color: #555;
            }

            &-close-button {
                padding: 10px 20px;
                background-color: #dc3545;
                color: white;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #c82333;
                }
            }

            img{
                max-width: 100%;
                height: auto;
                margin-top: 10px;
                border-radius: 8px;
                cursor: not-allowed;
                max-height: 400px;
            }
        }
    }

    // Media Query for Small Screens
    @media (max-width: 768px) {
        padding: 10px;

        &__title {
            font-size: 20px;
        }

        &__content {
            flex-direction: column; // Stack content vertically

            &-alert-list {
                margin-right: 0; // Remove margin on small screens
                margin-bottom: 20px; // Add space below alert list
            }

            &__summary {
                margin-top: 10px; // Reduce margin on small screens
            }
        }

        &__summary-title {
            font-size: 18px; // Adjust title size
        }

        &__summary-item {
            font-size: 14px; // Adjust item font size
        }

        &__update-button {
            width: 100%; // Make the update button full width
        }

        &__overlay-content {
            width: 90%; // Make overlay content responsive
            padding: 20px; // Adjust padding
        }
    }
}

/* Communications.scss */

/* Existing styles... */

@media (max-width: 450px) {

    .communications__update-button,
    .communications__content-alert-list-item__buttons__add-button,
    // .communications__content-alert-list-item__buttons__alert-remove-button,
    .communications__content-alert-list-item__buttons__preview-button {
        padding: 8px 16px; // Adjust padding for smaller buttons
        font-size: 14px; // Adjust font size for better readability
        min-width: 80px; // Set a minimum width for buttons
    }

    .communications__content-alert-list-item__buttons {
        flex-direction: column; // Stack buttons vertically if needed
        gap: 5px; // Add some space between buttons
    }
    .communications__overlay-content img{
        width: 50%;
    }
}

@media (max-width: 550px) {
    .communications__overlay-content img{
        width: 50%;
    }
}
