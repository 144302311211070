// seller/src/components/Alerts/Alerts.scss
.alerts {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    margin-bottom: 30px;
    max-width: 940px;

    &__title {
        font-size: 24px;
        margin-bottom: 20px;
        color: #333;

        h3 {
            font-size: 18px;
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 34px;
        height: 20px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: 0.4s;
            border-radius: 34px;
        }

        .slider:before {
            position: absolute;
            content: '';
            height: 14px;
            width: 14px;
            left: 3px;
            bottom: 3px;
            background-color: white;
            transition: 0.4s;
            border-radius: 50%;
        }

        input:checked+.slider {
            background-color: #000000;
        }

        input:checked+.slider:before {
            transform: translateX(14px);
        }
    }

    &__settings {
        margin-bottom: 20px;
    }

    .info-box {
        background-color: #f8f9fa;
        border: 1px solid #e9ecef;
        border-radius: 4px;
        padding: 16px;
        margin-bottom: 20px;

        ul {
            margin-top: 8px;
            padding-left: 20px;
            list-style-type: disc;

            li {
                margin-bottom: 8px;
                font-size: 14px;
                line-height: 1.5;

                strong {
                    font-weight: 600;
                }
            }
        }
    }

    // seller/src/components/Alerts/Alerts.scss

    .alerts__button-update {
        padding: 10px 20px;
        background-color: #000000;
        color: white;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin-top: 20px;

        &:hover {
            background-color: #ffffff;
            color: #000000;
            border: 1px solid #000000;
        }

        &:disabled {
            background-color: #c0c0c0;
            cursor: not-allowed;
        }
    }

    &__input-group {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .alerts__input {
            flex: 1;
            margin-right: 10px;
            padding: 10px;
            // border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;

            &:focus {
                border-color: #007bff;
                outline: none;
            }
        }

        .minimal-input {
            flex: 1;
            /* Allow the input to take available space */
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 14px;
            margin-right: 10px;
            /* Space between input and button */
            transition: border-color 0.3s;

            &:focus {
                outline: none;
                border-color: #007BFF;
                /* Change border color on focus */
            }
        }

        .minimal-button {
            padding: 10px 15px;
            background-color: #007BFF;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
                /* Darker shade on hover */
            }
        }

        .alerts__button {
            padding: 10px 15px;
            background-color: #000000;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: #ffffff;
                color: #000000;
                border: 1px solid #000000;
            }
        }
    }

    &__list {
        margin-top: 10px;
        padding: 10px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }

    &__list-item {
        padding: 5px 0;
        border-bottom: 1px solid #e0e0e0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
            border-bottom: none;
        }
    }
}