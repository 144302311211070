.main-content {
  // padding: 5rem 2rem;

  /* width: 100vw; */
  // padding: 5rem 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-hero {
    height: 80vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    border-bottom: 1px solid #ccc;

    &__offer-label {
      margin-top: 5px;
      font-size: 16px;
      color: #333; // Green color to indicate offer
      font-weight: 500;
      text-align: center;
      margin-bottom: 3rem;
      
    }

    &__title {
      font-size: 80px;
      margin-bottom: 35px;
      justify-content: center;
      display: flex;
      text-align: center;
      background-image: linear-gradient(90deg, #c058ff, #ff5858, #000);
      -webkit-background-clip: text;
      color: transparent;
      background-size: 200% 200%;
      animation: linear-gradient-animation 4s infinite linear;
    }

    @keyframes radial-gradient-animation {
      0% {
        background-position: 0% 50%;
      }

      100% {
        background-position: 100% 50%;
      }
    }

    &__input-group {
      display: flex;
      justify-content: center;
     
      position: relative;
    }

    &__prefix {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1rem;
      color: #333;
      z-index: 1;
      font-weight: 500;
    }

    input {
      padding: 0.5rem 1rem .5rem 118px;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 5px 0px 0px 5px;
      // margin-right: 1rem;
      flex: 1;
      max-width: 300px;
      font-weight: 500;
      height: 50px;
    }
    input::placeholder {
      font-weight: normal; /* This sets the placeholder text to normal weight */
  }

    button {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border: none;
      cursor: pointer;
      background-color: #000;
      color: #fff;
      border-radius: 0px 5px 5px 0px;
    }

    button:hover {
      background-color: white;
      color: black;
      border: 1px solid black;
    }
  }


  &-feature {
    display: flex;
    min-height: 100vh;
    align-items: center;

    width: 100%;
    // margin-bottom: 100px;
    justify-content: space-between;
    align-items: center;
    padding: 160px 0;

    &__text {
      width: 50%;
      padding: 0px 20px;
      // padding: 20px;
      text-align: left;

      h2 {
        font-size: 48px;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        color: #666;
      }
    }

    &__image {
      width: 50%;
      max-width: 400px;
      padding: 20px;
    }

    &--reverse {

      display: flex;
      min-height: 100vh;
      flex-direction: row-reverse;
      // margin-top: 50px;
      justify-content: space-between;
      align-items: center;
      // max-width: 1200px;
      padding: 160px 0;
      background-color: black;
      color: white;
      width: 100%;

      p {
        font-size: 16px;
        color: #7d7d7d;
        ;
      }
    }
  }
}

@media (max-width: 850px) {

  .main-content {

    &-hero__title {
      font-size: 60px;
    }

    input {
      max-width: 69vw;
      width: 100%;
    }

    button {
      font-size: 12px;
    }

    &-feature__text {

      h2 {
        font-size: 34px;
      }



    }
  }
}

@media (max-width: 600px) {

  .main-content {

    // &-hero__title {}

    &-feature,
    &-feature--reverse {

      flex-direction: column;
      padding: 160px 0;
      margin-bottom: 0;
      gap: 30px;

      img {
        width: 100%;
      }

      &__text {
        width: 100%;
        text-align: left;
        padding: 0px 40px;

        h2 {
          font-size: 50px;
        }



      }

      &-reverse {}
    }
  }
}

@media (max-width: 500px) {

  .main-content {

    // &-hero__title {}

    &-feature,
    &-feature--reverse {

      flex-direction: column;
      padding: 160px 0;
      margin-bottom: 0;

      &__text {
        width: 100%;
        text-align: left;

        h2 {
          font-size: 50px;
        }



      }

      &-reverse {}
    }
  }
}


@media (max-width: 350px) {

  .main-content {

    // &-hero__title {}

    &-feature,
    &-feature--reverse {


      &__text {


        h2 {
          font-size: 38px;
        }



      }

      &-reverse {}
    }
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color:#00000063;
  border-radius: 6px;
  border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}