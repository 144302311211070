@import '../../../styles/common.scss';

.policies-page {
    padding: 20px;
    margin-top: 20px;
    // height: 94vh;
    max-height: 100%;

    h1 {
        margin-bottom: 20px;
    }

    .policy-item {
        margin-bottom: 20px;
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: white;
        max-width: 650px;

        .policy-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            h2 {
                margin: 0;
            }
        }

        .policy-toggle {
            .switch {
                position: relative;
                display: inline-block;
                width: 34px;
                height: 20px;

                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    transition: 0.4s;
                    border-radius: 34px;

                    &:before {
                        position: absolute;
                        content: '';
                        height: 14px;
                        width: 14px;
                        left: 3px;
                        bottom: 3px;
                        background-color: white;
                        transition: 0.4s;
                        border-radius: 50%;
                    }
                }

                input:checked+.slider {
                    background-color: #000000;
                }

                input:checked+.slider:before {
                    transform: translateX(14px);
                }
            }
        }

        .policy-actions {
            display: flex;
            justify-content: flex-end;
            margin-top: 15px;
        }

        .edit-policy-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 8px 16px;
            background-color: #000000;
            color: #e1e0e0;
            border: none;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            cursor: pointer;
            transition: all 0.3s ease;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

            &:hover {
                background-color: #e0e0e0;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
                transform: translateY(-1px);
                color: #000000;
            }

            &:active {
                background-color: #d0d0d0;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
                transform: translateY(0);
            }

            &::before {
                content: '\270E'; // Unicode for a pencil icon
                margin-right: 8px;
                font-size: 16px;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 10px;

        .policy-item {
            padding: 10px;

            .policy-header {
                h2 {
                    font-size: 18px; // Reduced font size for small devices
                }
            }

            .edit-policy-btn {
                // width: 100%;
                justify-content: center;
            }
        }
    }

    .policy-description {
        background-color: #f0f4f8;
        border-left: 4px solid #3498db;
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 4px;

        p {
            color: #2c3e50;
            font-size: 14px;
            line-height: 1.6;
            margin: 0;
        }
    }

    .about-us {
        background-color: #f8f9fa;
        border-left: 4px solid #28a745;
    }

    .info-box {
        margin-bottom: 20px;

        ul {
            margin-top: 10px;
            padding-left: 20px;

            li {
                font-size: 0.9rem;
                margin-bottom: 5px;
            }
        }

        .MuiTypography-root {
            &[fontWeight="bold"] {
                color: #d32f2f; // A shade of red for emphasis
                font-size: 1rem;
            }
        }
    }
}
