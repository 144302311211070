.product-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9; // Light background for better contrast
}

.productPage {
    display: flex;
    flex-direction: row;
    // margin-top: 80px;
    // height: 70vh;
    width: 95vw;
    padding: 10px 20px 10px 20px;
    justify-content: center;
    min-height: 70vh;
    // align-items: center;

    
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    z-index: 10000;
}


    &__left {
        width: 50%;
        margin: 20px 20px 20px 20px;
        text-align: center;
        display: flex;

        .productPage__left-arrows {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: space-between;
            width: 100%;

            .arrow {
                display: inline-block;
                position: relative;
                font-size: 2rem;
                font-weight: bold;
                color: #0b2b66;
                cursor: pointer;
            }
        }

        &-images {
            flex: 1;

            .selected {}

            img.selected {
                border: 1px solid #dacccc;
                border-radius: 8px;
            }

            img {
                object-fit: contain;
                width: 53%;
                height: 65px;
                cursor: pointer;
                padding: 5px;
            }

            img:hover {}
        }

        &-mainImage {
            flex: 5;
            align-items: center;
            justify-content: center;
            /* text-align: center; */
            display: flex;

            img {
                object-fit: contain;
                width: 65%;
                border-radius: 10px;
            }

        }

    }

    &__right {
        width: 50%;
        margin: 20px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        p {
            color: #717070;
        }

        h1 {
            font-size: 20px;
        }

        &-price {
            margin-top: 20px;
            // color: red;
            color: #12c232;


            &-MRP {
                color: rgb(0, 0, 0);
                display: flex;
                flex-direction: row;
            }


        }

        &-qty {
            margin-top: 20px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            display: flex;
            justify-content: space-around;
            width: 100%;

            div {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                // justify-content: space-evenly;
            }

            .qty-label {
                font-size: 20px;
                margin-right: 10px;
            }

            .qty-button {
                font-size: 20px;
                background-color: #f0f0f0;
                border: none;
                padding: 5px 10px;
                cursor: pointer;

                &:hover {
                    background-color: #e0e0e0;
                }
            }

            .qty {
                font-size: 20px;
                margin: 0 10px;
            }

        }

        &-button {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .primaryButton-outline {
                width: 100%;
                height: 40px;
                background-color: rgb(255, 255, 255);
                color: black!important;
                border: 1px solid black;
                color: white;
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.3s ease;
                
                &:hover {
                    // background-color: rgba(236, 240, 234, 0.445);
                    border: 2px solid black;
                    // transform: scale(1.01);
                    // color: white!important;
                }
                
            }
            
            .primaryButton {
                width: 100%;
                height: 40px;
                background-color: black;
                color: white;
                border-radius: 5px;
                cursor: pointer;
                border: 1px solid black;
                

            }

            // button {
            //     width: 100%;
            //     height: 40px;
            //     background-color: black;
            //     color: white;
            //     border-radius: 5px;
            // }
        }
        span {
            color: #999; // Lighter color for the label
            font-size: 0.9rem; // Slightly smaller font size
            margin-top: 5px; // Space above the label
            display: block; // Ensure it appears on a new line
        }

        // &-description {
        //     line-height: 30px;
        //     margin-bottom: 20px;
        //     margin-top: 13px;

        //     p {
        //         font-weight: bold;
        //     }

        //     .link {
        //         color: blue;
        //         cursor: pointer;
        //     }

        //     li {
        //         list-style-type: none;
        //         display: flex;
        //         align-items: center;
        //         gap: 6px;
        //         margin: 5px 0px 5px 0px;

        //         svg {
        //             // transform: rotate(45deg);
        //             font-size: 16px;
        //         }
        //     }
        // }
    }




    .strikePrice {
        // color: rgba(red, 0.5);
        text-decoration: none;
        position: absolute;
        // color: black;
        color: rgb(255, 0, 0);

        &:before {
            content: " ";
            display: block;
            width: 100%;
            border-top: 2px solid rgba(0, 0, 0, 0.8);
            height: 12px;
            position: absolute;
            bottom: -7px;
            left: 3px;
            transform: rotate(-7deg);
        }
    }

    button:hover {
        color: #fff;
        background-color: #ffffff;
        // border-top: 2px solid rgba(0, 0, 0, 0.8);
        color: black;
        transition: all 0.2s ease-out;
    }
}

.product_video {
    margin: 20px 0px 20px 0px;
    padding: 10px 20px 10px 20px;
    margin-top: 2rem;
    // text-align: center;

    h2 {
        margin-bottom: 1rem;
    }

    iframe {
        max-width: 100%;
        border-radius: 10px;
    }

}

.basket-icon {
    position: fixed; // Change to fixed for fixed positioning
    bottom: 20px; // Adjust the distance from the bottom as needed
    // right: 20px; // Adjust the distance from the right as needed
    background-color: #000000;
    left: 20px;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%; // To make it round
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); // Add drop shadow effect

    svg {
        font-size: 24px;
    }
}

.whatsapp-icon {
    position: fixed; // Change to fixed for fixed positioning
    bottom: 20px; // Adjust the distance from the bottom as needed
    // left: 20px; // Adjust the distance from the right as needed
    // background-color: #000000;
    right: 20px;
    // color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%; // To make it round
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); // Add drop shadow effect

    svg {
        font-size: 24px;
    }

    img {
        height: 100%;
    }
}


@media (max-width: 980px) {
    .productPage__right-qty {
        .primaryButton {
            padding: 12px 30px;
        }
    }
}

@media (max-width: 868px) {
    .productPage {
        display: flex;
        // flex-direction: column;

        &__left {
            width: 40%;

            &-qty {
                padding: 12px 64px;
            }

            &-mainImage {
                img {

                    width: 50%;
                }
            }
        }

        img {
            object-fit: contain;

        }
    }

    .productPage__right-description {
        line-height: 20px;
    }
}

@media (max-width: 850px) {
    .productPage {
        gap: 10px;
        display: flex;
        flex-direction: column;
        // min-height: 85vh;
        // height: 60vh;
        // height: 100vh;
        // width: 100vw;
        align-items: center;

        &__left {
            width: 100%;
            margin: 0px;
        }

        &__right {
            // padding: 0px 14px 11px 14px;
            width: 100%;

            margin: 0px;
            // padding: 10px 30px 10px 30px;

            &-rating {}

            &-description {
                font-size: 12px;
            }


            &-button {
                align-items: center;
                display: contents;
            }


        }
    }
}

.star {
    font-size: 20px;
    color: #ffc107;
}

@media (max-width: 500px) {
    .productPage__right {
        .primaryButton {
            font-size: 15px;
            // padding: 12px 55px;
        }
    }
}

@media (max-width: 460px) {
    .productPage {

        // min-height: 100vh;
        img {
            object-fit: contain;

            // width: 250px;
        }
    }

    .productPage__right {
        .primaryButton {
            // font-size: 10px ;
            padding: none;
            width: 100%;
        }
    }

    .productPage__right-qty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 10px;

    }
}

@media (max-width: 380px) {
    .productPage {
        min-height: 90vh;

    }
}

@media (max-width: 330px) {
    .productPage {
        &__right {
            gap: 0px;
            // padding: 10px 20px 10px 20px;

            &-title {
                font-size: 13px;
            }

            &-description {
                line-height: 24px;
            }
        }

        img {
            // width: 220px;
        }
    }
}

@media (max-width: 234px) {
    .productPage {
        &__right {

            // padding: 10px 20px 10px 20px;

            &-title {
                font-size: 13px;
            }
        }

        min-height: 135vh;
    }
}

@media (max-width: 200px) {
    .productPage {
        min-height: 160vh;
    }
}

@media (max-height: 720px) {}

.loaderWrapper {
    position: relative;

    svg {}

}


/* Full-screen image overlay */
.fullscreen-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Ensure it appears on top of everything */
    cursor: pointer;
    img {
        max-width: 60%;
        max-height: 90%;
        border-radius: 10px;
        object-fit: cover;
        max-height: 90vh;
    }
}


/* Close button (optional) */
.fullscreen-image-overlay .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    z-index: 10000;
}

/* Style the close button as an 'X' */
.fullscreen-image-overlay .close-button::before {
    content: '✕';
}

/* Center the 'X' in the button */
.fullscreen-image-overlay .close-button::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Hide the close button on smaller screens if needed */
@media (max-width: 768px) {
    .fullscreen-image-overlay .close-button {
        display: none;
    }
    .productPage__left-mainImage img {
        height: auto; // Set to auto or a specific height
        max-height: 300px; // Adjust this value as needed
        width: 100%; // Ensure it takes full width
        object-fit: cover; // Maintain aspect ratio
    }
    .fullscreen-image-overlay img{
       
            max-width: 90%;
            max-height: 90%;
            border-radius: 10px;
            object-fit: cover;
        
    }
}


.swiper-slide {
    text-align: center;
}

.product-description, .productPage__right p {
    cursor: pointer;
    overflow: hidden;
    
    p {
        margin: 0;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    ul, ol {
        padding-left: 20px;
    }
}