.expired-plan {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    padding: 2rem;
  
    &__content {
      text-align: center;
      background-color: #fff;
      padding: 2rem;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      max-width: 400px;
      width: 100%;
    }
  
    &__title {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      color: #333;
    }
  
    &__message {
      font-size: 1rem;
      margin-bottom: 1.5rem;
      color: #666;
    }
  
    &__button {
      background-color: #007bff;
      color: #fff;
      border: none;
      padding: 0.75rem 1.5rem;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }